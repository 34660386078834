import React, { useState, useRef } from 'react';
import { UseMap } from 'react-map-gl';


import {
    Alert,
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Select,
    Option,
    Textarea,

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
} from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { showAlert } from './Alert.jsx';


export default function OfferCard(props) {

    const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);

    const [offerid, setOfferId] = useState(props.id);
    const [name, setName] = useState('');
    const [labname, setLabName] = useState();
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState();
    const [action, setAction] = useState('create');

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);


    const [refreshAfterExit, setRefreshAfterExit] = useState(false);


    React.useEffect(() => {
        if (offers.length > 0) {
            let array = offers.filter(offer => offer.id == offerid);
            if (array.length > 0) {
                let offer = array[0];
                setName(offer.name);
                setLabName(offer.labname);
                setPrice(offer.price);
                setDescription(offer.description);
                if (offerid > 0) setAction("update");
            }
        }

    }, []);





    function cancelClose() {
        props.close(refreshAfterExit);
    }


    function handleDataResponse(data) {
        console.log(data);

        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);
        }

        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }




    function handleSubmit(e) {
        e.preventDefault();

        var bodydata = JSON.stringify({ action: action, session: session, name: name, labname: labname, price: price, description: description, id: offerid });

        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/offers.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }




    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto z-[10] flex place-content-center'>

            <Card color="white" className="w-full max-w-[20rem] mx-auto py-4 shrink" shadow={false}>

                <div className="flex items-center justify-between gap-4 w-auto my-2">
                    <Typography color="gray" className="mt-2 pl-12 font-normal mx-auto">
                        Karta oferty badań
                    </Typography>
                    <IconButton variant='text' className='top-[-1rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>
                <form className="mt-4 mb-2 mx-auto w-full max-w-[18rem] sm:w-80 items-center justify-center p-2 gap-4" onSubmit={handleSubmit}>
                    <div className="mb-6 flex flex-col gap-6 w-auto items-center justify-center ">


                        <Input size="md" label="Nazwa badania (dla klienta)" className="shadow-md" value={name} onChange={e => setName(e.target.value)} />

                        <Input size="md" label="Nazwa badania (Laboratorium)" className="shadow-md" value={labname} onChange={e => setLabName(e.target.value)} />

                        <div className='mx-[4rem]'>
                            <Input size="md" label="Cena badania (Ha)" className="shadow-md justify-center text-center" value={price} onChange={e => setPrice(e.target.value)} />
                        </div>

                        <Textarea label="Uwagi" value={description} onChange={e => setDescription(e.target.value)} />

                        <Button className="items-center mt-2 mb-0 w-48 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 mx-32" data-refresh={true} fullWidth type="submit">
                            Zapisz
                        </Button>
                    </div>

                </form>
            </Card>

        </div>
    );


}

