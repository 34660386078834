import * as React from 'react';
import area from '@turf/area';
import {
  Card,
  Typography,
  IconButton,
  MenuItem,
} from "@material-tailwind/react";

function ControlPanel(props) {
  let polygonArea = 0;
  if (props.polygons)
    {
      for (const polygon of [props.polygons]) {
        polygonArea += area(polygon);
    };
  }
  
  function toFixed(value, precision) {
    var precision = precision || 0,
        power = Math.pow(10, precision),
        absValue = Math.abs(Math.round(value * power)),
        result = (value < 0 ? '-' : '') + String(Math.floor(absValue / power));

    if (precision > 0) {
        var fraction = String(absValue % power),
            padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');
        result += '.' + padding + fraction;
    }
    return result;
}
  

return (
      <div className="absolute bottom-0 mx-auto w-screen flex items-center ">
        <div className='inline-flex mx-auto gap-1'>
        <Card className="font-mono w-auto float-center mx-auto items-center bg-black/50 text-white px-4 py-2">
        <p>Lat : {toFixed(props.view.latitude,7)}</p>
        </Card>
        <Card className="font-mono w-auto float-center mx-auto items-center bg-black/50 text-white px-4 py-2">
        <p>Lng : {toFixed(props.view.longitude,7)}</p>
        </Card>
        <Card className="font-mono w-auto float-center mx-auto items-center bg-black/50 text-white px-4 py-2">
        <p>Zoom : {toFixed(props.view.zoom,1)}</p>
        </Card>

        
      {polygonArea > 0 && (
        <Card className="font-mono w-auto float-center mx-auto items-center bg-black/50 text-white px-4 py-2">
        <p>Powierzchnia : {Math.round(polygonArea/100 ) / 100} Ha</p>
        </Card>
      )}
        </div>
    </div>
  );
}

export default React.memo(ControlPanel);
