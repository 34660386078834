import React, { useState } from 'react';


import {
    Alert,
    Card,
    Input,
    Button,
    Typography,
    IconButton,
    Textarea,

} from "@material-tailwind/react";



import { } from "@heroicons/react/24/outline";

import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { showAlert } from './Alert.jsx';
import { sendSms } from './SendSms';




export default function SmsCard(props) {


    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);

    const [name, setName] = useState(props.name);
    const [phone, setPhone] = useState(props.phone);
    const [message, setMessage] = useState(props.message);
    const [action, setAction] = useState("senssms");
    const [encoding, setEncoding] = useState('');
    const [charsPerMessage, setCharsPerMessage] = useState(160);
    const [charsCount, setCharsCount] = useState(0);
    const [remainingChars, setRemainingChars] = useState(160);
    const [smsCounter, setSmsCounter] = useState('Jedna');
    const [totalChars, setTotalChars] = useState(160);
    const [sendDisabled, setSendDisabled] = useState(false);

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('red');


    const liczebniki = ["Jedna", "Dwie", "Trzy", "Cztery", "Pięć", "Sześć", "Siedem", "Osiem", "Dzeiwięć", "Dziesięć"]

    React.useEffect(() => {
        console.log('sms props');
        console.log(props);
        if (props.message) messageUpdate(props.message);

    }, []);




    function cancelClose() {
        props.close();
    }

    function hasUnicode(str) {
        for (var i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127) return true;
        }
        return false;
    }

    function messageUpdate(e) {

        setMessage(e);
        let unicode = hasUnicode(e);
        let charsPerMessage = 160;
        if (unicode) {
            setEncoding('utf-8')
            charsPerMessage = 70;
        }
        else {
            setEncoding('')
            charsPerMessage = 160;
        }
        setCharsPerMessage(charsPerMessage);
        let count = e.length;
        setCharsCount(count);
        let remain = charsPerMessage - count;
        let segments = 1;
        while (remain < 0) {
            segments++;
            remain += charsPerMessage;
        }

        if (segments > 10) {
            segments = 10
            setCharsCount(charsPerMessage * 10);
            setMessage(e.substring(0, charsPerMessage * 10)); //limit message lenght
            remain = 0;
        }

        setRemainingChars(remain);
        setTotalChars(segments * charsPerMessage);
        setSmsCounter(liczebniki[segments - 1]);
    }



    function handleDataResponse(data) {

        console.log(data);
        setSendDisabled(false); //tymczasowo odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);

            if (closeOnSend) { props.close(refreshAfterExit); }

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }



    function handleSubmit(e) {
        e.preventDefault();
        setSendDisabled(true); //tymczasowo zablokuj przycisk wyślij
        sendSms(session, phone, message, encoding, handleDataResponse); //wyślij sms
    }



    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto z-[30] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-full max-w-[20rem] mx-auto rounded-lg max-h-[94vh] overflow-y-auto" shadow={false}>

                <div className="flex items-center justify-between gap-4 w-auto bg-gradient-to-br from-teal-500/75 to-cyan-500/75 pt-2 pb-2 rounded-t-lg">
                    <Typography color="white" variant="h6" className="mt-2 pl-12 font-normal mx-auto">
                        Wyślij SMS
                    </Typography>
                    <IconButton variant='text' className='right-2' color="white" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>
                <form className="mt-6 mb-2 mx-auto w-full max-w-[20rem]  items-center justify-center px-6" onSubmit={handleSubmit}>
                    <div className="mb-6 flex flex-col gap-4 w-auto items-center justify-center ">


                        <Input size="md" label="Nazwa odbiorcy" className="shadow-md w-auto" value={name} readOnly={true} />

                        <Input size="md" label="Nr telefonu odbiorcy" className="shadow-md" value={phone} readOnly={true} />

                        <Textarea label="Wiadomość" value={message} onChange={e => messageUpdate(e.target.value)} />
                        <div className='flex flex-col items-center justify-center h-[3rem]'>
                            <span color="gray" variant="small" className="relative text-xs font-normal ">Użyłeś <span className="text-blue-800">{charsCount}</span> znaków,
                                pozostało jeszcze <span className="text-blue-800">{remainingChars}</span>
                            </span>

                            <span color="gray" variant="small" className="relative text-xs font-normal "><span className="text-blue-800">{smsCounter}</span> {smsCounter == "Jedna" ? "wiadomość" : "wiadmości"} to <span className="text-blue-800">{totalChars}</span> znaków.</span>
                            {(encoding == 'utf-8') && <span color="gray" variant="small" className="relative text-xs font-normal ">Wiadomość zawiera znaki specjalne (np. polskie)</span>}
                        </div>
                        <Button className="items-center max-w-[10rem] my-0 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 mx-32" disabled={sendDisabled} data-refresh={true} fullWidth type="submit">
                            Wyślij
                        </Button>
                    </div>

                </form>
            </Card>

        </div>
    );
}

