import './App.css';
import { Routes, Route } from "react-router-dom"
import Login from './pages/Login'

import About from "./pages/About"
import Contact from "./pages/Contact"

import Client from "./pages/Client"
import Admin from "./pages/Admin"
import Tech from "./pages/Tech"

function App() {
  return (
    <Routes>
        <Route path="/" element={ <Login/> } />
        <Route path="about" element={ <About/> } />
        <Route path="map" element={ <Client/> } />
        <Route path="manage" element={ <Admin/> } />
        <Route path="workflow" element={ <Tech/> } />
        <Route path="contact" element={ <Contact/> } />
      </Routes>
  );
}

export default App;
