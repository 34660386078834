import * as React from 'react';
import { useState, useRef, useCallback } from 'react';
import Map, { UseMap, NavigationControl, Source, Layer, MapProvider } from 'react-map-gl';


import DrawControl from './draw-control.tsx';
import ControlPanel from './control-panel.tsx';
import { Button } from '@material-tailwind/react';
import * as turf from '@turf/turf';



const dataLayer = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#e0e060',
    'fill-opacity': 0.8
  }
};





var emptyGeoJson = {
  type : "FeatureCollection",
  features: []
}

const fillStyle = {
  id: 'polygonfill',
  type: 'fill',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'fill-color': '#ffff80', // yellow color fill
    'fill-opacity': 0.5
  }
};

const lineStyle = {
  id: 'polygonlines',
  type: 'line',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'line-color': '#ffff80', //yellow line
    'line-width': 2
  }
};

const initialView = {
  longitude: 15.62129,
  latitude: 53.63918,
  zoom: 11
};




export default function MapboxPreview (props) {
  
  const [featureCollection, setFeatureCollection] = useState(JSON.parse(localStorage.getItem("featureCollection")) || emptyGeoJson);
  const [viewState, setViewState] = useState(initialView);
  const [boundingBox, setBoundingBox] = useState([]);

  const mapRefPreview = useRef();


  React.useEffect(() => {
    addEventListener('featureCollectionChanged', () => {
        setFeatureCollection(JSON.parse(localStorage.getItem("featureCollection")));
      });
      
      addEventListener('boundingBoxChanged', () => {
        let bbox=JSON.parse(localStorage.getItem("boundingBox"));
        setBounds(bbox);
        if (mapRefPreview.current) mapRefPreview.current.once('idle', getCanvas);
        setBoundingBox(bbox);
      });

      setFeatureCollection(JSON.parse(localStorage.getItem("featureCollection")));
      let bbox=JSON.parse(localStorage.getItem("boundingBox"));
      setTimeout(() => {
        setBoundingBox(bbox);
        setBounds(bbox);
        if (mapRefPreview.current) mapRefPreview.current.once('idle', getCanvas);
        }, 200);

      setBoundingBox(bbox);
},[]);  



  function resizeBoundingBox(bounds) {
    var point1 = turf.point([bounds[0], bounds[1]]);
    var point2 = turf.point([bounds[2], bounds[3]]);
    var bearing = turf.bearing(point1, point2);
    var distance = turf.distance(point1, point2);
    var p1 = turf.destination(point1, -0.5 * distance, bearing);
    var p2 = turf.destination(point2, 0.5 * distance, bearing);
    var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
    return box;
  }


  function setBounds(bounds) {
    if (mapRefPreview.current!==null) {
        mapRefPreview.current.fitBounds(bounds,{duration: 0});
    }
  }

  function applyPreviewImage(image) {
    localStorage.setItem('previewImage', image);
    dispatchEvent(new Event('previewImageChanged'));
}



  function getCanvas() {
    var img = mapRefPreview.current.getCanvas().toDataURL('image/png');
    applyPreviewImage(img);    
  }


  return (
    <Map
      ref={mapRefPreview}
      mapLib={import('mapbox-gl')}
      mapboxAccessToken="pk.eyJ1Ijoia2lsbGVyZWsiLCJhIjoiY2xrdXlkdDVrMDNmcjNkcXprcXNhaXAzaiJ9.PXuJL9WHJpLU4cJBCu8nhA"
      movingMethod="jumpTo"
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      style={{ position: 'relative', width: '200px', height: '160px', top: 0 }}
      
      attributionControl={false}
      preserveDrawingBuffer={true}
    >
        
        <Source id="fc" type="geojson" data={featureCollection}>
          <Layer {...fillStyle} />
          <Layer {...lineStyle} />
        </Source> : ''

    </Map>

  );
}

