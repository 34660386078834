import React from 'react';
import { Page, Text,  View, Font, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';

import {
  Badge,
  Chip,
  Navbar,
  Collapse,
  Typography,
  Table,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  ListItemPrefix,
  ListItemSuffix,
  MenuItem,
  Avatar,
  Card,
  IconButton,
  ListItem,
  TabsHeader,
} from "@material-tailwind/react";


import {

  CubeTransparentIcon,
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
  PowerIcon,
  PlusIcon,
  DocumentPlusIcon,
  RocketLaunchIcon,
  ClipboardDocumentListIcon,
  EnvelopeIcon,
  MapIcon,
  PlusCircleIcon,
  Bars2Icon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { UNSAFE_mapRouteProperties } from 'react-router';

// Create styles
Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

// Create style with font-family

const __styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontFamily: "Roboto",
    fontSize: 10
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  textSmall: {
    fontSize: 7,
    margin: 0,
    padding: 0,
    height: 10,
  },
  textMedium: {
    fontSize: 10,
    margin: 0,
    padding: 0,
  },
  textLarge: {
    fontSize: 14,
    margin: 0,
    padding: 0,
  },
  textFar: {
    fontSize: 16,
    margin: 0,
    padding: 0,
    top: 200,
    left: 200,
    borderTop: 1,
    maxWidth:100,
    borderRight: 1,
  },
  

});

const _xstyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    fontFamily: "Roboto",
    fontSize: 10
  },

  table: {
    width: '100%',
    margin: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 1,
    borderRight: 1,
    borderLeft: 1,
  },
  header: {
    borderTop: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  // So Declarative and unDRY 👌
  row1: {
    width: '8%',
    borderRight: 1,
    alignItems: "center",
    margin: 2
  },
  row2: {
    width: '15%',
    borderRight: 1,
    margin: 2
  },
  row3: {
    width: '15%',
    borderRight: 1,
    margin: 2
  },
  row4: {
    width: '20%',
    borderRight: 1,
    margin: 2
  },
  row5: {
    width: '27%',
    margin: 2
  },
})


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontFamily: "Roboto",
    fontSize: 10,
    marginTop: 30, 
  },

  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginLeft: 30,
    marginRight: 30, 
    marginTop: 210, 
    fontSize: 10
  }, 

  table_cont: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginLeft: 30,
    marginRight: 30, 
    marginTop: 20, 
    fontSize: 10
  }, 


  zlecenieHeader: { 
    margin: "auto", 
    flexDirection: "row", 
    fontSize: 14,
    fontWeight : 300,
    top: 10
  }, 

  data: { 
    position: "absolute",
    flexDirection: "column", 
    fontSize: 10,
    fontWeight : 100,
    left: 480,
    top: 2

  }, 

  nabywca: { 
    position: "absolute",
    flexDirection: "column", 
    fontSize: 10,
    fontWeight : 100,
    left: 30,
    top: 60
  }, 

  info: { 
    position: "absolute",
    flexDirection: "column", 
    fontSize: 10,
    fontWeight : 100,
    left: 30,
    top: 160
  }, 


  zleceniodawca: { 
    position: "absolute",
    flexDirection: "column", 
    fontSize: 10,
    fontWeight : 100,
    left: 280,
    top: 60
  }, 

  bold: { 
    fontWeight : 400,
    fontSize: 12,
  }, 
  

  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 

  tableRowHead: { 
    margin: "auto", 
    flexDirection: "row", 
    backgroundColor : "#d0d0d0"
  }, 

  tableCol1: { 
    width: "5%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol2: { 
    width: "12%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol3: { 
    width: "20%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol4: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol5: { 
    width: "13%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol6: { 
    width: "35%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableCell: { 
    margin: "auto", 
    marginTop: 3, 
    marginBottom: 3, 
    fontSize: 10 
  },

  tableCellTop: { 
    margin: "auto", 
    marginTop: 3, 
    marginBottom: 0, 
    fontSize: 10 
  },
  
  tableCellBottom: { 
    margin: "auto", 
    marginTop: 1, 
    marginBottom: 3, 
    fontSize: 10 
  }

});

// Create Document Component
export function LabFormDocument({props}) {
 console.log("lab form props");
 console.log(props);
 console.log("lab form props.client.name");
 console.log(props.client.name);
 
 let pages=[];
 
 let probe=1;
 let page=1;
let style=styles.table;

 while (probe<=props.probes)
  {
    let max=34;
    if (page==1) max=24; 
    if ((probe+max)>=(props.probes)) max=props.probes-probe+1;
    if (max<=0) break;
    console.log("probe")
    console.log(probe)
    console.log("max")
    console.log(max)
    let probearray=[];

    for (let i=1;i<=max;i++)
      {
         let item={id: probe, fieldmark: props.fieldmark, analysis: props.analysis};
         probearray.push(item);
         probe++;
      }
    pages.push({ array:probearray, style: style});
    style=styles.table_cont;
    page++; 
  }



function Table(props) {
  return (
    <View style={props.tablestyle}> 
        <View style={styles.tableRowHead}> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>#</Text> 
          </View> 
          <View style={styles.tableCol2}> 
          <Text style={styles.tableCellTop}>Oznaczenie</Text> 
            <Text style={styles.tableCellBottom}>próbki</Text> 
          </View> 
          <View style={styles.tableCol3}> 
            <Text style={styles.tableCellTop}>Zakres</Text> 
            <Text style={styles.tableCellBottom}>badania</Text> 
          </View> 
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCellTop}>Symbol</Text> 
            <Text style={styles.tableCellBottom}>Pola</Text> 
          </View> 
          <View style={styles.tableCol5}> 
            <Text style={styles.tableCell}>Powierzchnia</Text> 
          </View> 
          <View style={styles.tableCol6}> 
            <Text style={styles.tableCell}>Uwagi</Text> 
          </View> 
        </View>
        
        {props.probearray.map((item) => (
        <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>{item.id}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
            <Text style={styles.tableCell}></Text> 
          </View> 
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>{item.analysis}</Text> 
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{item.fieldmark}</Text> 
          </View> 
          <View style={styles.tableCol5}> 
            <Text style={styles.tableCell}>--</Text> 
          </View> 
          <View style={styles.tableCol6}> 
            <Text style={styles.tableCell}></Text> 
          </View> 
        </View> 
        ))
        }
      </View> 
  )
}










 return (
 <Document>
    {
    pages.map(({ array, style }, index) => {
      const firstpage = index === 0;
      return ( 
     <Page size="A4" style={styles.page}>
    
    {firstpage &&
    <View >
      <Text style={styles.zlecenieHeader}>
      Zlecenie badania próbki ziemi
      </Text>
      <Text style={styles.zlecenieHeader}>
      numer {props.jobnumber}
      </Text>
      <Text style={styles.data}>Łobez {props.date}</Text>
    </View>
    }
    
    {firstpage &&
    <View style={styles.nabywca}>
      <Text style={styles.bold}>Nabywca :</Text>
      <Text>Piotr Mazur - AGROTECHNOLOGY</Text>
      <Text>ul. Zaświat 26/14</Text>
      <Text>85-685 Bydgoszcz</Text>
      <Text>NIP: 8512493524</Text>
      <Text>email : kontakt@agrotechnology.pl</Text>            
    </View>
    }
    
    {firstpage &&
    <View style={styles.zleceniodawca}>
      <Text style={styles.bold}>Zleceniodawca :</Text>
      <Text>{props.client.name}</Text>
      <Text>{props.client.address}</Text>
      <Text>{props.client.postalcode} {props.client.city}</Text>            
    </View>
    }

    {firstpage &&
    <View style={styles.info}>
      <Text style={styles.bold}>Ustalenia :</Text>
      <Text>- Wyrażam zgodę na wystawienie faktury VAT (na dane nabywcy) bez podpisu.</Text>
      <Text>- W przypadku niewskazania metody badania proszę o kontakt na email nabywcy.</Text>
      <Text>- Zapoznałem się z Zasadami Ochrony Danych Osobowych (art. 13 ust. 2 RODO) i wyrażam zgodę</Text>
      <Text> na przetwarzanie moich danych.</Text>            
    </View>
    }
    <Table probearray={array} tablestyle={style}/>
    
    </Page>
    )})
    }
  </Document>
)};


export function PdfPreview(props) {

  function cancelClose() {
    props.close();
  }


  return (
    <div className='absolute w-full h-full top-0 left-0 items-center shadow-md justify-cener z-[50] flex'>
      <Card color="white" className="w-full max-w-[80vw] h-[calc(100vh-4rem)] mx-auto my-auto py-4 shrink shadow-xl" shadow={true}>
        <div className="flex items-center justify-between gap-4 w-auto my-2">
          <Typography color="gray" className="mt-2 pl-12 font-normal mx-auto">
            Podgląd raportu
          </Typography>
          <IconButton variant='text' className='top-[-1rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
            <XMarkIcon className='w-6 h-6' />
          </IconButton>
        </div>
        <PDFViewer className='h-full'>
          <LabFormDocument props={props.props}/>
        </PDFViewer>
      </Card>
    </div>

  )
}

