

//http://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow?language=pol?SERVICE=WMS&REQUEST=GetFeatureInfo&VERSION=1.3.0&LAYERS=dzialki%2Cgeoportal%2Cnumery_dzialek%2Cbudynki%2Ckontury%2Cuzytki&QUERY_LAYERS=dzialki%2Cgeoportal%2Cnumery_dzialek%2Cbudynki%2Ckontury%2Cuzytki&CRS=EPSG%3A2180&BBOX=646450.5793609876%2C274101.67117259087%2C649006.4594727479%2C277324.3026178538&WIDTH=1218&HEIGHT=966&I=241&J=251&INFO_FORMAT=text%2Fxml&FORMAT=image%2Fpng&STYLES=


function parseGeoportalInfo(data) {
    let json = [];
    const layerStart = '<Layer Name="integracja">';
    const laStartLen = layerStart.length;

    const attributeName = '<Attribute Name="';
    const atNameLen = attributeName.length;

    const attributeNameEnd = '">';
    const atNameEndLen = attributeNameEnd.length; //hmm 2 ? :)

    const attributeEnd = '</Attribute>';
    const atEndLen = attributeEnd.length;

    const linkStart = '<![CDATA';

    let start = data.indexOf(layerStart)
    if (start <= 0) return null;
    start += laStartLen;
    data = data.substr(start);
    //now data starts with first attribute
    let atNameStart = 0;
    do {
        atNameStart = data.indexOf(attributeName);
        if (atNameStart >= 0) {
            atNameStart += atNameLen;
            let atNameEnd = data.indexOf(attributeNameEnd);
            let name = data.substring(atNameStart, atNameEnd);

            let atValueStart = atNameEnd + atNameEndLen;
            let atValueEnd = data.indexOf(attributeEnd);
            let value = data.substring(atValueStart, atValueEnd);

            if (!value.startsWith(linkStart)) { //ignore links
                json[name] = value;
            }
            data = data.substr(atValueEnd + atEndLen); //cut parsed attribute
        }


    } while (atNameStart >= 0);
    return json;
}



export function geoportalGetFeatureInfo(bbox, width, height, point, callback) {
    console.log('query integracja');
    if (!bbox._sw) return;
    if (!bbox._ne) return;
    var query = "https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow?language=pol?SERVICE=WMS&REQUEST=GetFeatureInfo&VERSION=1.3.0&LAYERS=dzialki%2Cgeoportal%2Cnumery_dzialek%2Cbudynki%2Ckontury%2Cuzytki&QUERY_LAYERS=dzialki%2Cgeoportal%2Cnumery_dzialek%2Cbudynki%2Ckontury%2Cuzytki&" +
        "CRS=EPSG%3A4326&BBOX=" + bbox._sw.lat + "," + bbox._sw.lng + "," + bbox._ne.lat + "," + bbox._ne.lng +
        "&WIDTH=" + width +
        "&HEIGHT=" + height +
        "&I=" + point.x + "&J=" + point.y +
        "&INFO_FORMAT=text%2Fxml&FORMAT=image%2Fpng&STYLES=";

    fetch(query, {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        referrer: 'strict-origin'
    })
        .then(async response => {
            const data = await response.text();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            let parsed = parseGeoportalInfo(data);
            callback(parsed);
        })
        .catch(error => {
            console.log({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
}



export function geoportalGetFeatureInfoOld(bbox, width, height, point, callback) {
    console.log('query integracja');

    var query = "https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow?language=pol?SERVICE=WMS&REQUEST=GetFeatureInfo&VERSION=1.3.0&LAYERS=dzialki%2Cgeoportal%2Cnumery_dzialek%2Cbudynki%2Ckontury%2Cuzytki&QUERY_LAYERS=dzialki%2Cgeoportal%2Cnumery_dzialek%2Cbudynki%2Ckontury%2Cuzytki&" +
        "CRS=EPSG%3A4326&BBOX=" + bbox._sw.lat + "," + bbox._sw.lng + "," + bbox._ne.lat + "," + bbox._ne.lng +
        "&WIDTH=" + width +
        "&HEIGHT=" + height +
        "&I=" + point.x + "&J=" + point.y +
        "&INFO_FORMAT=text%2Fxml&FORMAT=image%2Fpng&STYLES=";

    fetch(query, {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        referrer: 'strict-origin'
    })  
        .then(res => console.log(res))
        .then(body => console.log(body))
        
        .catch(error => {
            console.log({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
}






function parseGeoportalPolygon(data, ewid) {
    let geometry = { coordinates: [], type: "Polygon", id: ewid };
    let feature = { type : "Feature" , geometry : null};

    const polyStart = 'POLYGON(';
    const polyStartLen = polyStart.length;

    const polyEnd = ')';
    const polyEndLen = polyEnd.length;
    const comaLen = 1;

    let iStart = data.indexOf(polyStart)

    if (iStart <= 0) return;

    data = data.substr(iStart + polyStartLen);

    while (data.startsWith("(")) {
        let polygon = []
        let shapeEnd = data.indexOf(polyEnd);
        let shapedata = data.substring(1, shapeEnd);

        let shape = shapedata.split(",");
        shape.forEach(point => {
            let coords = point.split(" ");

            let lng = parseFloat(coords[0]);
            let lat = parseFloat(coords[1]);
            let lngLat = [lng, lat];
            polygon.push(lngLat);

        })
        geometry.coordinates.push(polygon);
        data = data.substr(shapeEnd + polyEndLen + comaLen);
    }
    feature.geometry=geometry;
    feature.properties={};
    feature.properties.polygonName=ewid;    
    return feature;
}




export function geoportalGetParcelPolygon(ewid,callback) {
    var query = "https://uldk.gugik.gov.pl/?request=GetParcelById&id=" + ewid + "&result=geom_wkt&srid=4326";
    fetch(query, {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        referrer: 'strict-origin'
    })
        .then(async response => {
            const data = await response.text();

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            let polygon = parseGeoportalPolygon(data, ewid);
            if (callback) callback(polygon);
        })
        .catch(error => {
            console.log({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });

}