import React from 'react'

import MapBox from './MapBox'
import { Sidebar } from './Sidebar';

import AlertCard from "./Alert";

function Tech() {
    return (
      <div className='h-screen w-screen fixed'>
      
        <MapBox />

        <Sidebar /> 
        
        <AlertCard />
      </div>
    );
  }
  
export default Tech;
  