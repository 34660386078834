import React, { useState } from 'react';

import {
    Badge,
    Card,
    Input,
    IconButton,
    Button,
    Tooltip,
    MenuItem,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,


} from "@material-tailwind/react";
import {
    MapIcon,
    MagnifyingGlassIcon,
    PencilSquareIcon,
    UserPlusIcon,
    UserIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";


import CrewCard from './CrewCard';


export default function CrewList() {
    const [crew, setCrew] = useState([]);
    const [memberId, setMemberId] = useState(0);

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('');
    const [showCrewCard, setShowCrewCard] = useState(false);


    React.useEffect(() => {
        getCrew();
    }, []);


    function getCrew() {
        console.log('crewlist');
        var bodydata = JSON.stringify({ action: 'crewlist', session: session });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/user.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'

        })
            .then(res => res.json())
            .then(data => { console.log(data); setCrew(data.crew); localStorage.setItem("crew", JSON.stringify(data.crew)); })

    }


    const handleShowCrewCard = (e) => {
        const id = e.currentTarget.getAttribute("data-memberid");
        setMemberId(id);
        setShowCrewCard(showCrewCard === true ? false : true);
    };



    function CrewItem(props) {
        var usertooltip = props.isonline ? 'Online' : 'Offline' + " ostatnia aktywność " + props.lastseen;
        var color = props.isonline ? 'green' : 'red';
        return (
            <div className='flex flex-row ml-0'>
                <ListItem className="h-12 " data-memberid={props.id} onClick={handleShowCrewCard}>
                    <ListItemPrefix>
                    <Tooltip content={usertooltip}>
                        <UserIcon className='h-[18px] w-[18px]' color={color}/>
                    </Tooltip>
                    </ListItemPrefix>
                    <span className='text-sm max-w-[12rem]'>{props.lastname + " " + props.firstname}</span>
                    <span className='ml-auto mr-0 text-xs lowercase'>{props.rankname}</span>
                </ListItem>

            </div>
        )
    }

    /**
                     <MenuItem>
                        <Badge overlap="circular" >
                        <Tooltip content={usertooltip}>
                         <UserIcon className='w-6 h-6 rounded-full' /> 
                        </Tooltip>
                        </Badge>
                        <Typography size="sm" variant='text' className='w-[12rem] text-center text-slate-800 normal-case' data-memberid={props.id}>{props.firstname + " " + props.lastname}</Typography>
                    

                    <Typography size="sm" variant='text' className='w-[4rem] text-left text-slate-800 normal-case'>{props.rankname}</Typography>
                    
                    
                    
                    <Tooltip content="Edytuj dane pracownika">
                        <IconButton size="sm" variant='text' className='ml-auto justify-self-end' color="blue-gray" data-memberid={props.id} onClick={handleShowCrewCard}>
                            <PencilSquareIcon className='w-6 h-6' />
                        </IconButton>
                    </Tooltip>
                    </MenuItem>                    

     */

    function showHideCard(refresh) {
        setShowCrewCard();
        if (refresh == true) getCrew();
    }


    function RenderCrewList() {
        if (Array.isArray(crew)) {
            return (
                crew.map((member) => (
                    <CrewItem firstname={member.firstname} lastname={member.lastname} isonline={member.isonline} rankname={member.rankname} id={member.id} lastseen={member.lastseen} />
                )
                ))
        }
    }



    return (
        <>
            <Card className={`absolute h-[calc(100vh-2rem)] w-full max-w-[20rem] p-2 shadow-xl top-[1rem] lg:left-[17rem] left-[6rem] z-10 overflow-y-auto ${showCrewCard==true ? 'opacity-75' : 'opacity-100'}`}>
                <div className="mb-2 p-1 flex ml-auto mr-[1rem]">
                    <Tooltip content="Dodaj nowego pracownika">
                        <IconButton size="md" variant="gradient" className='px-4 mt-4 mr-0' color="green" data-memberid={0} onClick={handleShowCrewCard} >
                            <UserPlusIcon className='w-5 h-5' color="white" strokeWidth={2} />
                        </IconButton>
                    </Tooltip>
                </div>
                <List>
                    <RenderCrewList />
                </List>
            </Card>
            ${showCrewCard === true ? <CrewCard close={showHideCard} id={memberId} /> : ''}
        </>
    );
}

