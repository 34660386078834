import React, { useState, useRef } from 'react';


import pdficon from "../icons/pdf.png"
import zipicon from "../icons/zip.png"
import csvicon from "../icons/csv.png"
import shpicon from "../icons/shp.png"
import shxicon from "../icons/shx.png"
import txticon from "../icons/txt.png"
import othericon from "../icons/other.png"


export default function DocumentIcon(props) {
    let icon;
    switch (props.extension) {
        case "csv" : icon=csvicon; break;
        case "shp" : icon=shpicon; break;
        case "pdf" : icon=pdficon; break;
        case "zip" : icon=zipicon; break;
        case "txt" : icon=txticon; break;
        case "shx" : icon=shxicon; break;
        default : icon=othericon; 
    }

 
    return (
    <div className={props.className}>
        <img src={icon} className={props.innerClassName}></img>
    </div>
  );
}





