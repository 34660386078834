import React, { useState } from 'react';


import {
    Alert,
    Card,
    Input,
    Button,
    Option,
    Typography,
    IconButton,
    Select,
    Textarea,
    Tooltip

} from "@material-tailwind/react";

import { } from "@heroicons/react/24/outline";

import {
    ExclamationTriangleIcon,
    XMarkIcon,
    TrashIcon,
    SparklesIcon,
    RectangleGroupIcon,
    FolderPlusIcon,
    //DocumentIcon,
    DocumentChartBarIcon,
    FolderArrowDownIcon,
} from '@heroicons/react/24/outline';

import { showAlert } from './Alert.jsx';
import ShapeUnziper from './ShapeUnziper.jsx';
import Datepicker from "react-tailwindcss-datepicker";
import { downloadDocument, getDocuments, getFieldFiles } from './AdminTools.jsx';
import ConfirmDialog from './ConfirmDialog.jsx';
import DocumentIcon from './DocumentIcon.jsx';


const CATEGORIES = [
    { category: "invoice", name: "Faktura VAT" },
    { category: "results", name: "Wyniki badań" },
    { category: "shapedata", name: "Mapa wyników badań" },
    { category: "fertilization", name: "Mapa nawożenia" },
    { category: "company", name: "Dokumenty firmowe" },
    { category: "internal", name: "Dokumenty wewnętrzne" },
    { category: "personal", name: "Dokumenty pracownika" },
    { category: "holiday", name: "Karta urlopowa" },
]

const FIELD_CATEGORIES = [
    { category: "invoice", name: "Faktura VAT" },
    { category: "results", name: "Wyniki badań" },
    { category: "shapedata", name: "Mapa wyników badań" },
    { category: "fertilization", name: "Mapa nawożenia" },
]


export default function FileUploadCard(props) {


    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [documents, setDocuments] = useState(JSON.parse(localStorage.getItem("documents")) || []);
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem("files")) || []); //!!! -> field files actually
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []); //for test is client or admin

    const [id, setId] = useState(props.id || 0);
    const [title, setTitle] = useState();
    const [fileName, setFileName] = useState();
    const [fileSize, setFileSize] = useState();
    const [dateString, setDateString] = useState();
    const [examDate, setExamDate] = useState();
    const [fileType, setFileType] = useState();
    const [fileDate, setFileDate] = useState();
    const [extension, setExtension] = useState();
    const [owner, setOwner] = useState(props.clientid || 0);
    const [field, setField] = useState(props.fieldid || 0);
    const [category, setCategory] = useState(props.category);
    const [properties, setProperties] = useState();
    const [data, setData] = useState();
    const [jsonData, setJsonData] = useState();
    const [categories, setCategories] = useState(CATEGORIES);
    const [action, setAction] = useState("create");
    const [fileInfo, setFileInfo] = useState();

    const [windowTitle, setWindowTitle] = useState("Karta pliku");

    const [sendDisabled, setSendDisabled] = useState(true);
    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend, setCloseOnSend] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const emptyGeoJson = {
        "type": "FeatureCollection",
        features: []
    }
    let collection = emptyGeoJson;

    React.useEffect(() => {
        if (props.id > 0) {
            console.log(' props.id ' + id);
            setAction("modify");
            console.log(' documents ');
            console.log(documents);
            let farray = documents.filter(d => d.id == props.id);
            console.log(' farray ');
            console.log(farray);
            if (farray.length == 1) getSelectedFile(farray[0]);
            setSendDisabled(false);
        }
        if (props.fieldid) {
            setWindowTitle("Pliki powiązane")
            setCategories(FIELD_CATEGORIES);
        }
    }, []);



    function cancelClose() {
        props.close(refreshAfterExit);
    }

    function onCategoryChange(e) {
        setCategory(e);
        setSendDisabled(false);
    }

    function onDateChange(e) {
        console.log(e);
        setExamDate(e);
        setSendDisabled(false);
    }


    function refreshDocuments() {
        getDocuments(session, setDocuments);
        if (field) getFieldFiles(session, field, setFiles);   //// important    
    }


    function getFileInfo(file) {
        //console.log('file info ');
        //console.log(file);
        setFileInfo(file);
        setFileName(file.name);
        setExtension(file.name.split('.').pop());
        setFileSize(file.size);
        setFileType(file.type);
        setFileDate(file.lastModified);
        setExamDate(file.date);
    }

    function getSelectedFile(file) {

        setId(file.id);
        setAction("modify");

        setFileName(file.filename);
        setExtension(file.extension);
        setFileType(file.type);
        setField(file.field);
        setTitle(file.title);
        setCategory(file.category);
        let props = JSON.parse(file.properties);
        setFileType(props.type);
        setFileSize(props.size);
        setFileDate(props.date);
        //setDateString(props.date);
        
        console.log('file.date');
        console.log(file.date);
        
        
        let examstamp=Date.now();
        
        //works for date and timesamp
        let fileExam=Date.parse(file.date);
        if (isNaN(fileExam)) { fileExam=Number(file.date);  }
        if (!isNaN(fileExam)) examstamp=fileExam;

        setExamDate({ startDate: examstamp, endDate: examstamp }); 

    }



    function getFileData(data) {
        setId(0); //new file
        setAction("create");
        setJsonData();

        setData(data); //binary data
        setSendDisabled(false);
    }

    function collectFeatures(feature) {
        console.log('collect');
        console.log(feature);
        if (!feature) return;
        collection.features.push(feature);
    }


    function collectFinished() {
        console.log('finished');
        setJsonData(collection);
        collection = emptyGeoJson;
        setSendDisabled(false);
    }


    var shpwrite = require("shp-write-update");

    function download_json() {
        var options = {
            folder: 'granice',
            types: {
                point: 'points',
                polygon: 'granice',
                polyline: 'lines'
            },
            file: 'granice'
        }
        // a GeoJSON bridge for features
        console.log('json data');
        console.log(jsonData);

        let parsed = JSON.parse(jsonData);
        shpwrite.download(parsed, options);
    }


    function handleDownload() {
        downloadDocument(session, id, field);
    }




    function handleDataResponse(data) {

        console.log(data);
        setSendDisabled(false); //tymczasowo odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);

            refreshDocuments();


            if (closeOnSend) { props.close(refreshAfterExit); }

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }

    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }


    function base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }




    function handleSubmit(e) {
        e.preventDefault();
        setSendDisabled(true); //tymczasowo zablokuj przycisk wyślij

        if (category == "internal") { setOwner(0); setField(0); }

        let properties = { size: fileSize, type: fileType, date: fileDate }
        let ext=fileName.split('.').pop();
        let date=Date.parse(examDate.startDate);
        
        var bodydata = JSON.stringify({ action: action, session: session, id: id, owner: owner, field: field, filename: fileName, title: title, category: category, title: title, extension: ext, date: date, properties: JSON.stringify(properties), jsondata: JSON.stringify(jsonData), binarydata: arrayBufferToBase64(data) });

        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/files.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }


    function handleRemoveFile() {
        //setCloseOnSend(true);
        setRefreshAfterExit(true);
        setShowConfirmDelete(false);
        var bodydata = JSON.stringify({ action: "remove", session: session, id: id });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/files.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }



    const renderCatOption = (item, index) => {
        return (
            <Option key={item.category} value={item.category}>{item.name}</Option>
        );
    };




    const selectFile = (e) => {
        const id = e.currentTarget.getAttribute("data-fileid");
        try {
            let file = files.filter(f => f.id == id)[0];
            getSelectedFile(file);
            setSendDisabled(false);
        }
        catch { }
    };


    return (
        <div className='fixed w-full h-full top-0 left-0 items-center justify-center m-auto z-[20] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-full max-w-[21rem] mx-auto rounded-lg max-h-[94vh] " shadow={false}>

                <div className="flex items-center justify-between gap-4 w-auto bg-gradient-to-br from-teal-500/75 to-cyan-500/75 pt-2 pb-2 rounded-t-lg">
                    <Typography color="white" variant="h6" className="mt-2 pl-12 font-normal mx-auto">
                        {windowTitle}
                    </Typography>
                    <IconButton variant='text' className='right-2' color="white" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>

                {files.length > 0 &&
                    <div className='w-full mx-[1rem] mt-2 flex flex-wrap gap-2 overflow-x-auto'>
                        {files.map(({ id, title, filename, extension, category }) => {
                            if (!category) category="results";
                            let catname = categories.filter(c => c.category == category)[0].name;
                            console.log(filename,extension);    

                            return (
                                <div className="flex flex-col gap-1 border rounded-md w-[6rem] max-h-[12rem]" data-fileid={id} onClick={selectFile}>

                                    <DocumentIcon extension={extension} className='h-12 w-14 mx-auto my-1 flex' innerClassName="pl-2"></DocumentIcon>

                                    <div className='line-clamp-2'>
                                        <Typography variant="small" label="Tytuł" className="text-center mx-auto text-bold text-blue-500" >{title}</Typography>
                                    </div>
                                    {/*
                                    <div className='line-clamp-2'>
                                        <Typography variant="small" label="Tytuł" className="text-center mx-auto text-xs " >{filename}</Typography>
                                    </div>
                                    */}
                                    <div className='w-full line-clamp-2 justify-center items-center mb-0 mt-auto'>
                                        <Typography variant="small" label="Tytuł" className="text-center text-xs mx-auto text-blue-700" >{catname}</Typography>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                }

                <form className="mt-6 mb-2 mx-auto w-full max-w-[20rem]  items-center justify-center px-6 " onSubmit={handleSubmit}>
                    <div className="mb-4 flex flex-col w-auto gap-6 items-center justify-center ">

                        <div className='flex flex-row w-full justify-evenly'>
                            {/* 
                            {id > 0 &&
                                <IconButton variant='gradient' className='right-2' color="purple" data-refresh={false} onClick={download}>
                                    <FolderArrowDownIcon className='w-6 h-6' />
                                </IconButton>
                            }
                            */}
                            {id == 0 &&
                                <ShapeUnziper className="ml-0 mr-0 mt-[2rem]" iconOnly={true} fullbutton={true} geoJson={collectFeatures} finished={collectFinished} fileinfo={getFileInfo} filedata={getFileData} tooltip="Dodaj nowy plik" allowpdf={true} />
                            }
                        </div>
                        {fileName &&
                            <div className='w-full justify-left items-left flex-col'>
                                <Input size="md" label="Nazwa pliku" className="shadow-md w-auto" value={fileName} onChange={e => setFileName(e.target.value)} />
                                <Typography variant="small" label="Typ" className="text-left ml-1 mt-1 text-xs" >Typ pliku : {fileType}</Typography>
                                <Typography variant="small" label="Rozmiar" className="text-left ml-1 text-xs" >Rozmiar : {fileSize} </Typography>
                                <Typography variant="small" label="Data Modyfikacji" className="text-left ml-1 text-xs" >Zmodyfikowany : {dateString} </Typography>
                            </div>
                        }

                        <Input size="md" label="Tytuł pliku (opis)" className="shadow-md w-auto" value={title} onChange={e => setTitle(e.target.value)} />

                        <Select label="Kategoria pliku" className='mb-8' value={category} onChange={onCategoryChange}>
                            {categories.map(renderCatOption)}
                        </Select>


                        {category=="shapedata" &&  
                        <div className='flex max-w-[12rem] w-[12rem] mt-2 mb-6'>
                        {/**
                        <Input size="md" color="teal" label="Rok badania" className="shadow-md text-center" value={examDate} onChange={onDateChange} />
                         */}
                        <Datepicker i18n={"pl"} primaryColor="teal" asSingle={true} useRange={false} value={examDate} onChange={onDateChange} inputClassName="w-full border rounded-md shadow-md py-2.5 px-2 border-gray-400 text-gray-900 text-normal text-sm focus:border-blue-500 focus:outline-0 focus:ring-0" placeholder="Data badania" ></Datepicker>
                        </div> 
                        }   
                    
                    
                    </div>
                    <div className='flex flex-row w-full justify-between mb-4 mt-4'>
                        {clients.length>0 &&
                            <Tooltip content="Usuń plik">
                                <IconButton variant="filled" color="red" size="md" className='mx-auto w-32 h-32' onClick={() => setShowConfirmDelete(true)} disabled={id == 0} >
                                    <TrashIcon className='w-5 h-5' color="white" />
                                </IconButton>
                            </Tooltip>
                    }
                        
                        {id > 0 &&
                            <Tooltip content="Pobierz plik">
                                <Button className="items-center w-auto bg-gradient-to-br from-purple-400 to-purple-500 mx-auto" onClick={handleDownload}>
                                    Pobierz
                                </Button>
                            </Tooltip>
                        }

                        <Button className="items-center w-auto bg-gradient-to-br from-teal-500 to-cyan-500 mx-auto" disabled={sendDisabled} data-refresh={true} fullWidth type="submit">
                            Zapisz
                        </Button>
                    </div>

                </form>
            </Card>
            <ConfirmDialog open={showConfirmDelete} message="Czy na pewno chcesz usunąć to pole" onCancel={() => setShowConfirmDelete(false)} onConfirm={handleRemoveFile} />
        </div>
    );
}

