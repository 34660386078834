import React, { useState } from 'react';

import {
    Alert,
} from "@material-tailwind/react";

import {
    ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';

export function showAlert(color, message, timeout) {
    let details = { color: color, message: message, timeout: timeout }
    dispatchEvent(new CustomEvent('showAlert', { detail: details })); //cool can pass arguments in event this way
}




export default function AlertCard() {
    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState("green");
    const [alertMessage, setAlertMessage] = useState("");
    

    React.useEffect(() => {

        addEventListener('showAlert', (e) => {
            if (e.detail) setUpAlarm(e.detail);
        })
    }, []);


    function setUpAlarm(detail)
    {
        setAlertMessage(detail.message);
        setAlertColor(detail.color);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
          }, detail.timeout);
    }


    return (
        <Alert open={showAlert}
            color={alertColor}
            onClose={() => setShowAlert(false)}
            variant="gradient" className='absolute top-8 right-8 w-96 shadow-lg z-[100]'
            animate={{ mount: { y: 0 }, unmount: { y: 100 }, }}>
            <span className='inline-flex'><ExclamationTriangleIcon className='w-6 mr-2 mt-0.5' />{alertMessage}</span>
        </Alert>
    )
}
