import React, { useState, useRef } from 'react';


import {
  Badge,
  Chip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import { getField } from "./AdminTools";

export function ViewFieldCard(props) {
  return (
    <Card className="w-auto border-2 border-cyan-100 hover:border-cyan-600">

      <CardBody className="text-center h-20 mb-0 mt-0">
        <Typography variant="h7" color="blue-gray" className="mb-0 mt-0">
          {props.fieldname}
        </Typography>
      </CardBody>

      <CardHeader floated={false} className="h-32 mt-0">
        <Badge content={props.area} overlap="square" color="green" placement="bottom-end" className="rounded-md mb-2 mr-4">
          <img src={props.img} alt="pole" className="h-32" />
        </Badge>


      </CardHeader>

      <CardFooter className="flex justify-center h-16">
        <Typography color="blue-grey" variant='small' className="font-xsmall float-center">
          {props.cropname}
        </Typography>
      </CardFooter>

    </Card>
  );
}

export function ViewFieldCardSmall(props) {
  return (
    <Card className="w-auto max-w-[60%] border border-cyan-200 hover:border-cyan-600">

      <CardBody className="text-center m-0 p-1">
        <p color="blue-gray" className="font-small m-1 p-0 text-sm line-clamp-2">
          {props.fieldname}
        </p>
      </CardBody>

      <CardHeader floated={false} className="h-16 mt-1 p-0">
        <Badge content={props.area} overlap="circular" color="green" placement="bottom-end" className="rounded-md mr-2 mb-2 px-1">
          <img src={props.img} alt="pole" className="h-16" />
        </Badge>

      </CardHeader>

      <CardFooter className="flex justify-center m-1 p-0">
        <p color="blue-grey" variant='small' className="text-sm float-center">
          {props.cropname}
        </p>
      </CardFooter>

    </Card>
  );
}




/**
 * 
      <Card className="w-full border border-cyan-200 hover:border-cyan-600 m-0 p-1">
        <Typography className="self-align-center mx-auto my-0" >{props.name}</Typography>
        <div className="flex flex-row p-0">
            <img src={props.img} alt="pole" className="w-[100px] h-[80px] rounded-md" />
          <div className="flex flex-col">
            <span color="blue-gray" className="font-small m-1 p-0 text-sm line-clamp-2">
              {props.crops}
            </span>
            <span color="blue-gray" className="font-small m-1 p-0 text-sm line-clamp-2 self-end">
              {props.area}
            </span>
          </div>
        </div>
      </Card>
 
* 
 */


export function VerticalFieldCard(props) {
  return (
    <Card className="max-w-[10rem] overflow-hidden">
      <CardHeader floated={false} shadow={false} color="transparent" className="m-0 rounded-none" >
        
        <img src={props.img} alt="pole"/>
        
      </CardHeader>
      <CardBody>
        <Typography variant="h6" color="blue-gray">
          {props.name}
        </Typography>
        <Typography variant="small" color="gray" className="mt-1 font-normal">
          {props.crops}
        </Typography>
      </CardBody>
      <CardFooter className="flex items-center justify-between mt-0 p-2">
        <div className="flex items-center -space-x-3">
        </div>
        <Typography className="font-normal">{props.area} Ha</Typography>
      </CardFooter>
    </Card>
  )
}


export function HorizontalFieldCard(props) {
  const [field,setField] = useState();
  const [image, setImage] = useState(props.img);
  const [session, setSession] = useState(localStorage.getItem("session") || null);

  React.useEffect(() => {
    //getFields(session,setFields, props.clientid); //pobierz listę pól
    //if (!image) getField(session, getFieldData, props.owner, props.id);
    
  }, []);
  
  function getFieldData(data)
  {
    console.log(data);
    if (data.length==1)
      if (data[0].image) setImage(data[0].image);
  }

  
  return (
    <Card className="w-full max-w-[48rem] h-[120px] flex-row border-cyan-200 hover:border-cyan-600 border">
      
      <CardHeader shadow={false} floated={false} className="m-0 w-1/2 shrink-0 rounded-r-none">
        <div className='absolute bottom-0.5 flex w-full items-end'>
          {/**
        <Typography variant="h6" color="white" className="text-xs text-white self-end ml-1 mr-auto">{props.area} Ha</Typography>
         */}
        <Chip size="sm" variant='gradient' color="yellow" className='text-xs py-0 px-1 normal-case mx-auto'  value={props.area+" Ha"}/>
        </div> 
      <img src={image} className="h-full w-full object-cover" />
      </CardHeader>

      <CardBody className="flex flex-col items-center justify-between w-full p-1">
      
        <Typography variant="h6" color="gray" className="my-auto text-xs text-center">
          {props.name}
        </Typography>
        <Typography variant="h6" color="blue-gray" className="my-auto text-xs">
          {props.crops}
        </Typography>
        {props.ownername &&
        <Typography variant="h6" color="black" className="my-auto text-xs text-center">
          {props.ownername}
        </Typography>
        }
        
        <div className='flex w-full justify-between px-1 flex-wrap gap-y-1'>
        <Chip size="sm" variant='ghost' color="gray" className='text-xs py-0 px-1'  value="2022" />
        <Chip size="sm" variant='ghost' color="gray" className='text-xs py-0 px-1'  value="2023" />
        <Chip size="sm" variant='ghost' color="gray" className='text-xs py-0 px-1'  value="2024" />
        </div> 
        
        {/**
        <Typography variant="h6" color="blue-gray" className="my-auto text-xs">
          {props.area} Ha
        </Typography>
         */}
      </CardBody>
      {/**
      <div className='flex w-[4rem] justify-between px-1 gap-y-0.5 absolute bottom-1 left-0 flex-wrap'>
        
        <Chip size="sm" variant='filled' color="gray" className='text-xs py-0 px-1'  value="2021" />
        <Chip size="sm" variant='filled' color="gray" className='text-xs py-0 px-1'  value="2022" />
        <Chip size="sm" variant='filled' color="gray" className='text-xs py-0 px-1'  value="2023" />

        <Chip size="sm" variant='ghost' color="gray" className='text-xs py-0 px-1'  value="2022" />
        <Chip size="sm" variant='ghost' color="gray" className='text-xs py-0 px-1'  value="2023" />
        <Chip size="sm" variant='ghost' color="gray" className='text-xs py-0 px-1'  value="2024" />
        </div> 
      */}    
    </Card>

  )
}  