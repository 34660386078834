

export function getClients(session, setClients, callback) {
  var bodydata = JSON.stringify({ action: 'clients', session: session });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/user.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      setClients(data.clients);
      localStorage.setItem("clients", JSON.stringify(data.clients));
      if (callback) callback();
    })
}



export function getOffers(session, setOffers) {
  var bodydata = JSON.stringify({ action: 'list', session: session });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/offers.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      setOffers(data.offers);
      localStorage.setItem("offers", JSON.stringify(data.offers));
    })
}


export function getOrders(session, setOrders) {
  var bodydata;
  bodydata = JSON.stringify({ action: 'list', sid: session });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/orders.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'

  })
    .then(res => res.json())
    .then(data => {
      console.log('got orders data');
      console.log(data);
      if (data.status == "ok") {
        setOrders(data.orders);
        if (data.orders) {
          localStorage.setItem("orders", JSON.stringify(data.orders))
        };
      }
    })
}


export function getFields(session, setFields, ownerid = 0) {
  var bodydata;
  bodydata = JSON.stringify({ action: 'list', session: session, owner: ownerid });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/fields.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'

  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      if (data.status == "ok") {
        setFields(data.fields);
        if (data.fields) {
          //localStorage.setItem("fields", JSON.stringify(data.fields));
        }
      }
    }) // może jednak nie dawać do local storage
}


export function getFieldsInfo(session, setFields, ownerid = 0) {
  var bodydata;
  bodydata = JSON.stringify({ action: 'listinfo', session: session, owner: ownerid });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/fields_test.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'

  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      if (data.status == "ok") {
        if (data.fields) {
          localStorage.setItem("fields", JSON.stringify(data.fields));
        }
      }
    }) // skrócone dane mogą iść do local storage
}


export function getField(session, setField, ownerid, fieldid) {
  var bodydata;
  bodydata = JSON.stringify({ action: 'get', session: session, owner: ownerid, id: fieldid });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/fields_test.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'

  })
    .then(res => res.json())
    .then(data => {
      //console.log(data);
      if (data.status == "ok") {
        if (data.field) {
          setField(data.field);
          //localStorage.setItem("field", JSON.stringify(data.field));
        }
      }
    }) // skrócone dane mogą iść do local storage
}



export function getVehicles(session, setVehicles) {
  var bodydata = JSON.stringify({ action: 'list', session: session });
  //console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/vehicles.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      if (data.status == "ok") {
        setVehicles(data.vehicles);
        if (data.vehicles) {
          localStorage.setItem("vehicles", JSON.stringify(data.vehicles))
        };
      }
    })
}


export function getFieldFiles(session, fieldid, setFiles, mode) {
  if (!mode) mode="basic";
  var bodydata = JSON.stringify({ action: 'fieldfiles', session: session, field: fieldid, mode: mode });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/files.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log('field files');
      console.log(data);
      if (data.status == "ok") {
        setFiles(data.files);
        if (data.files) {
          localStorage.setItem("files", JSON.stringify(data.files))
        };
      }
    })
}


export function getDocuments(session, setDocuments) {
  var bodydata = JSON.stringify({ action: 'documents', session: session, mode: "basic" });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/files.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      if (data.status == "ok") {
        setDocuments(data.files);
        if (data.files) {
          localStorage.setItem("documents", JSON.stringify(data.files))
        };
      }
    })
}



function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}



export function downloadDocument(session, id, field) {
  var bodydata = JSON.stringify({ action: 'download', session: session, id: id , field: field});
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/files.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      if (data.status == "ok") {
        console.log(data);
        if (!data.files[0]) return;
        let blob = new Blob([base64ToArrayBuffer(data.files[0].binarydata)]);
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        if (data.files) {
          try
          {
          alink.download = data.files[0].filename;
          alink.click();
          } catch {};
        }
        window.URL.revokeObjectURL(fileURL);

      }
    })
    .catch(error => {
      console.log({ errorMessage: error.toString() });
      console.error('There was an error!', error);
  });

}




export function listStorageItems() {
  var _lsTotal = 0,
  _xLen, _x;
  for (_x in localStorage) {
  if (!localStorage.hasOwnProperty(_x)) {
      continue;
  }
  _xLen = ((localStorage[_x].length + _x.length) * 2);
  _lsTotal += _xLen;
  console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
  };
console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
}



