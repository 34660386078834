import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    IconButton,
    Typography,
    Tooltip,
} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";

export function PopUpCard(props) {

    return (
        <Card className="w-auto rounded-md max-w-120 p-0 absolute top-1/4 right-2">

            <IconButton variant='text' size="sm" className='ml-auto mr-0 mt-0' color="blue-gray" data-refresh={false} onClick={props.close}>
                <XMarkIcon className='w-4 h-4' />
            </IconButton>
            {/*
            <span >
                {
                    props.content.map((line) => (
                        <div className="px-4 h-5 flex-row items-center justify-center">
                            {line.item && <span className="w-24 max-w-48 font-bold text-blue-900 float-left">{line.item}</span>}
                            <span className="font-normal float-right w-32 max-w-80 break-normal line-clamp-3">{line.value}</span>
                        </div>
                    ))
                }

            </span>
            */}
            <table className="w-auto px-4 mb-2">
                <tbody>
                    {
                        props.content.map(({item,value}, index) => (
                            <tr key={index} className="even:bg-blue-gray-50/50 h-5">
                                <td className="font-bold text-blue-900 px-2 min-h-4 max-w-48">{item}</td>
                                <td className="text-right justify-right max-w-80 pr-2 min-h-4 line-clamp-3 break-normal text-ellipsis">{value}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
            {(props.waiting==true && props.timeout==false) && <span className="font-normal items-center justify-center text-center w-full mx-auto mb-2 mt-2 px-2">Czekam na dane ewidencyjne</span>}
            {props.timeout==true && <span className="font-normal items-center justify-center text-center w-full mx-auto mb-2 mt-2 px-2">Geoportal nie odpowiada</span>}
        </Card>

    );
}