import React, { useState, useRef } from 'react';



import {
    Alert,
    Card,
    Input,
    Checkbox,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Select,
    Option,
    Textarea,

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function ConfirmDialog(props) {

    return (
        <Dialog open={props.open} size={"xs"} >
            <DialogHeader>Jesteś pewien ?</DialogHeader>
            <DialogBody divider>
                {props.message}
            </DialogBody>
            <DialogFooter>
                <Button variant="gradient" color="red" onClick={() => props.onCancel()} className="mr-8">
                    <span>Nie</span>
                </Button>
                <Button variant="gradient" color="green" onClick={() => props.onConfirm()}>
                    <span>Tak</span>
                </Button>
            </DialogFooter>
        </Dialog>
    )
}



export function QueryDataDialog(props) {
    const [data, setData] = useState('');
    
    function dataChange(e) {
        setData(e.target.value);
    }

    return (
        <Dialog open={props.open} size={"xs"} >
            <DialogHeader><Typography variant="small">{props.message}</Typography></DialogHeader>
            <DialogBody divider>
                <Textarea label="Identyfikatory Działek" value={data} onChange={dataChange}/>
            </DialogBody>
            <DialogFooter>
                <Button variant="gradient" color="green" onClick={() => props.onConfirm(data)}>
                    <span>Zapisz</span>
                </Button>
            </DialogFooter>
        </Dialog>
    )
}