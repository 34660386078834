import React, { useState } from 'react';

import {
  Card,
  Input,
  IconButton,
  Button,
  Tooltip,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,

} from "@material-tailwind/react";
import {
  MapIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  UserPlusIcon,
  UserIcon,
  BriefcaseIcon,
  PaperClipIcon,

} from "@heroicons/react/24/outline";


import ClientCard from './ClientCard';
import CompanyCard from './CompanyCard';
import { getFields, getFieldsInfo } from './AdminTools';

export default function ClientsList(props) {
  const [clients, setClients] = useState(localStorage.getItem("clients") || []);
  const [clientid, setClientId] = useState(0);
  const [masterid, setMasterId] = useState(0);
  const [search, setSearch] = useState('');
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [failed, setFailed] = useState(false);
  const [message, setMessage] = useState('');
  const [showClient, setShowClient] = useState(false);
  const [showCompany, setShowCompany] = useState(false);

  React.useEffect(() => {
    getClients();
  }, []);


  function getClients() {
    var bodydata = JSON.stringify({ action: 'clients', session: session });
    console.log(bodydata);
    fetch('https://agro-online.pl/cgi-bin/user.py', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: bodydata,
      mode: 'cors'

    })
      .then(res => res.json())
      .then(data => { console.log(data); setClients(data.clients); localStorage.setItem("clients", JSON.stringify(data.clients)); })
  }

  const showClientCard = (e) => {
    const id = e.currentTarget.getAttribute("data-clientid");
    setClientId(id);
    setShowClient(showClient === true ? false : true);
  };


  function showClientOrCompanyCard(e) {
      const id = e.currentTarget.getAttribute("data-clientid");
      setClientId(id);
      const master = e.currentTarget.getAttribute("data-master");
        if (master>0) {
          //company
          setMasterId(master);
          setShowCompany(showCompany === true ? false : true);
        }
        else
        {
          //client
          setShowClient(showClient === true ? false : true);
        }

  }



  const selectClient = (e) => {
    const id = e.currentTarget.getAttribute("data-clientid");
    console.log('klient id');
    console.log('id');
    setClientId(id);
    props.onSelect(id);
    getFields(session,() => void 0,id);
    getFieldsInfo(session,() => void 0,id);
  };




  function ClientItem(props) {
    let tip="Wybierz klienta do kontekstu"
    let edittip="Edytuj dane klienta"
    let filetip="Pokaż pliki powiazane z klientem"
    let master=0;
    if (props.master>0) 
      {
        tip="Wybierz spółkę do kontekstu";
        edittip="Edytuj dane spółki"
        filetip="Pokaż pliki powiazane ze spółką"
        master=props.master;
      }

    console.log(props.master);
    
    return (
      <div className='flex flex-row ml-0'>
        <ListItem className="h-10 " key={props.id} data-clientid={props.id}  onClick={selectClient}>
          <ListItemPrefix className='px-0 mr-2 ml-0'>
          {master==0 && <Tooltip content={tip}>
           <UserIcon className='h-[18px] w-[18px]' />
           </Tooltip>
           }
          {master>0 && <Tooltip content={tip}>
           <BriefcaseIcon className='h-[18px] w-[18px]' />
           </Tooltip>
           }

          </ListItemPrefix>
          <Tooltip content={props.name}>
            <span className='text-xs text-left  line-clamp-2'>{props.name}</span>
          </Tooltip>
          <ListItemSuffix>
            <Tooltip content={tip}>
              <span className='ml-auto mr-0 text-xs text-center capitalise w-[3rem]'>{props.city}</span>
            </Tooltip>
          </ListItemSuffix>
        </ListItem>
        <Tooltip content={edittip}>
          <IconButton size="sm" variant='outlined' className='mt-1 px-2' color="blue" data-clientid={props.id} data-master={master} onClick={showClientOrCompanyCard}>
            <PencilSquareIcon className='w-4 h-4' />
          </IconButton>
        </Tooltip>
        <Tooltip content={filetip}>
          <IconButton size="sm" variant='outlined' className='mt-1 ml-1 px-2' color="purple" data-clientid={props.id} data-master={master} onClick={showClientOrCompanyCard}>
            <PaperClipIcon className='w-4 h-4' />
          </IconButton>
        </Tooltip>

      </div>
    )
  }

  /**
    </div>
  
        <div className="flex items-center justify-between gap-1 w-auto my-1">
          <div>
            <Button size="sm" variant='text' className='w-[14rem] text-left text-slate-800 normal-case'>{props.name}</Button>
            <Tooltip content="Edytuj dane klienta">
            <IconButton size="sm" variant='text' className='' color="blue-gray" data-clientid={props.id} onClick={showClientCard}>
              <PencilSquareIcon className='w-6 h-6' />
            </IconButton>
            </Tooltip>
            <Tooltip content="Pokaż pola klienta">
            <IconButton size="sm" variant='text' className='' color="blue-gray" >
              <MapIcon className='w-6 h-6' />
            </IconButton>
            </Tooltip>
          </div>
        </div>
   
  * 
   * @param {*} refresh 
   */

  function hideClientCard(refresh) {
    setShowClient(false);
    if (refresh == true) getClients();
  }
  function hideCompanyCard(refresh) {
    setShowCompany(false);
    if (refresh == true) getClients();
  }


  function RenderClientList() {
    var select = [];
    var lsearch = search.toLowerCase();
    console.log(search);
    if (search.length == 0 || search == '') { select = clients; }
    else if (search.length == 1) { select = clients.filter(c => c.name.toLowerCase().startsWith(lsearch)); }
    else { select = clients.filter(c => c.name.toLowerCase().includes(lsearch)); }
    console.log(select)
    if (Array.isArray(select)) {
      return (
        select.map((client) => (
          <ClientItem name={client.name} id={client.id} city={client.city} master={client.master}/>
        )
        ))
    }
  }



  return (
    <>
      <Card className={`absolute h-[calc(100vh-2rem)] w-full max-w-[22rem] pl-0 pr-2 shadow-xl top-[1rem] lg:left-[17rem] left-[6rem] z-10 overflow-y-auto ${showClient==true ? 'opacity-75' : 'opacity-100'}`}>
        <div className="mb-2 p-2 flex mx-auto">
          <Input label="Szukaj" onChange={e => setSearch(e.target.value)} />
          <MagnifyingGlassIcon className='relative right-7 top-2 h-6 w-6 text-blue-500' />
          <Tooltip content="Dodaj klienta">
            <IconButton size="md" variant="gradient" className='px-4' color="green" data-clientid={0} onClick={showClientCard} >
              <UserPlusIcon className='w-5 h-5' color="white" strokeWidth={2} />
            </IconButton>
          </Tooltip>
        </div>
        <RenderClientList />
      </Card>
      {showClient === true ? <ClientCard close={hideClientCard} id={clientid} /> : ''}
      {showCompany === true ? <CompanyCard close={hideCompanyCard} id={clientid} master={masterid} /> : ''}
    </>
  );
}

