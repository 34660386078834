import React, { useState, useRef } from 'react';
import { UseMap } from 'react-map-gl';


import {
    Alert,
    Badge,
    Card,
    Input,
    Checkbox,
    Button,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Select,
    Option,
    Textarea,
    Tooltip

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    ArrowTopRightOnSquareIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    PencilIcon,
    TrashIcon,
    SparklesIcon,
    RectangleGroupIcon,
    BeakerIcon,
    FolderPlusIcon,
    //DocumentIcon,
    DocumentChartBarIcon,
    PaperClipIcon,
    FunnelIcon,

} from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ShapeUnziper from './ShapeUnziper.jsx';

import * as turf from '@turf/turf'

import MapboxPanel from './MapBoxPanel.jsx';
import { json } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import ConfirmDialog, { QueryDataDialog } from './ConfirmDialog.jsx';
import { showAlert } from './Alert.jsx';
import { geoportalGetParcelPolygon } from './geoportal.js';
import FileUploadCard from './FileUploadCard.jsx';
import { getFieldFiles } from './AdminTools.jsx';
import DocumentIcon from './DocumentIcon.jsx';
import { features } from 'process';
import { matchFieldsResults } from './GeoTools.jsx';


export default function ResultsProcessingCard(props) {

    const [featureCollection, setFeatureCollection] = useState(JSON.parse(localStorage.getItem("featureCollection")) || emptyGeoJson);
    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem("files")) || []);
    const [results, setResults] = useState([]); //
    const [labFile, setLabFile] = useState();
    const [labFeatures, setLabFeatures] = useState({});
    const [bounds, setBounds] = useState();

    const [ownerid, setOwnerId] = useState();
    const [shape, setShape] = useState();
    const [action, setAction] = useState('create');
    const [fieldid, setFieldId] = useState(0);
    const [fileid, setFileId] = useState(props.fileid);
    const [examTimeStamp, setExamTimeStamp] = useState();
    const [examDate, setExamDate] = useState();

    const [fileIsSelected, setFileIsSelected] = useState(false);
    const [clearOnExit, setClearOnExit] = useState(false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);

    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend, setCloseOnSend] = useState(false);
    const [showConfirmClear, setShowConfirmClear] = useState(false);
    const [sendDisabled, setSendDisabled] = useState(true);

    const emptyGeoJson = {
        "type": "FeatureCollection",
        features: []
    }

    let collection = emptyGeoJson;
    let expectFeatures = 0;


    React.useEffect(() => {
        init(props);



        //localStorage.setItem("lockvehicles", "true");
        //if (props.fieldid) getFieldFiles(session, props.fieldid, updateFiles,"json");   //// important    

    }, []);


    React.useEffect(() => {
        let res = files.filter(f => f.category == "shapedata");
        setResults(res);
    }, [files]);



    function gotFiles(files) {
        try {
            let shapefiles = files.filter(f.category == 'shapedata');
            if (shapefiles.length > 0) {
                setResults(shapefiles);
            }
        } catch { };
    }


    function init(props) {

        let fieldFeatures;
        if (props.field.shape) {
            fieldFeatures = JSON.parse(props.field.shape);
            fieldFeatures.features.forEach(element => {
                element.properties.value = 5;
            })

        }


        setFeatureCollection(fieldFeatures);
        applyFeatures(fieldFeatures);

        setOwnerId(props.clientid);
        setFieldId(props.fieldid);
        if (props.fieldid > 0) {
            setAction('update');
            getFieldFiles(session, props.fieldid, gotFiles, "json");


            let fieldarray = fields.filter(f => f.id == props.fieldid);
            if (fieldarray.length == 1) {
                let field = fieldarray[0];
                setOwnerId(field.owner);
            }
        }
    }



    function waitForResults(features, results) {

        showAlert("green", "Przetwarzanie zakończone", 2000);
        setFileIsSelected(true);
        setFeatureCollection(features);
        setLabFeatures(results);
        console.log('features after match');
        console.log(features);

        setSendDisabled(false);
    }



    function processResults() {

        setFileIsSelected(false);
        showAlert("green", "Przypisuję wyniki badań do pól", 2000);

        setTimeout(() => matchFieldsResults(featureCollection, labFeatures, examTimeStamp, labFile.id, waitForResults), 200);

    }



    function applyFeatureCollection(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: collection }));
    }

    function applyFeatures(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { features: collection } }));
    }

    function applyFocused(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { focused: collection } }));
    }

    function applyLabFeatures(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { labFeatures: collection } }));
    }


    function applyBoundingBox(bounds) {
        //localStorage.setItem('boundingBox', JSON.stringify(bounds));
        dispatchEvent(new CustomEvent('boundingBoxChangedPanel', { detail: bounds })); //cool can pass arguments in event this way
    }


    function clearAllResults() {
        featureCollection.features.forEach(element => {
            delete element.exam_percent;
            delete element.area;
            delete element.examined;
        })
    }

    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }


    function handleFileResponse(data) {

        console.log(data);
        setSendDisabled(false); //odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 2000);
            setRefreshAfterExit(true);

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 3000);
        }
    }


    function handleFieldResponse(data) {

        console.log(data);
        setSendDisabled(false); //odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 2000);
            setRefreshAfterExit(true);

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 3000);
        }
    }


    function updateLabFile() {
        setSendDisabled(true); //tymczasowo zablokuj przycisk wyślij
        var properties = JSON.parse(labFile.properties);
        properties.parsed = Date.now();
        var bodydata = JSON.stringify({ action: "update", session: session, id: labFile.id, owner: labFile.owner, field: labFile.field, filename: labFile.fileName, title: labFile.title, category: labFile.category, extension: labFile.ext, date: labFile.date, properties: JSON.stringify(properties), jsondata: JSON.stringify(labFeatures), binarydata: arrayBufferToBase64(labFile.data) });

        console.log('Update file');
        console.log(bodydata);
         
        fetch('https://agro-online.pl/cgi-bin/files.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleFileResponse(data))
        
    }


    function updateField() {

        try {
            let field = fields.filter(f => f.id == fieldid)[0];
            console.log('field');
            console.log(field);

            var bodydata = JSON.stringify({ action: "updateshape", session: session, id: fieldid, shape: JSON.stringify(featureCollection) });
            console.log('Update field shape');
            console.log(bodydata);

            fetch('https://agro-online.pl/cgi-bin/fields_test.py', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: bodydata,
                mode: 'cors'
            })
                .then(res => res.json())
                .then(data => handleFieldResponse(data))


        }
        catch { }
    }


    function saveProcessResults() {
        setSendDisabled(true);
        updateField();
        updateLabFile();
    }


    function zeroPad(n) {
        return n > 9 ? "" + n : "0" + n;
    }


    function selectFile(e) {
        const id = e.currentTarget.getAttribute("data-fileid");
        let file;
        let labFeats;
        try {
            file = results.filter(r => r.id == id)[0];
            setLabFile(file);
            labFeats = JSON.parse(file.jsondata);

            for (let i = 0; i < labFeats.features.length; i++) {
                labFeats.features[i].properties.value = 9;
            }

            setLabFeatures(labFeats);
            applyLabFeatures(labFeats);
            setFileIsSelected(true);

            featureCollection.features.forEach(element => {
                delete element.exam_percent;
                delete element.area;
            })

            //works for date and timesamp
            let fileExam = Date.parse(file.date);
            if (isNaN(fileExam)) { fileExam = Number(file.date); }
            if (!isNaN(fileExam)) {
                setExamTimeStamp(fileExam);

                let date = new Date(fileExam);
                console.log('date');
                console.log(date);

                setExamDate(date.getFullYear() + "-" + zeroPad(date.getMonth() + 1) + "-" + zeroPad(date.getDay()));


            }

            setFeatureCollection(featureCollection);

        } catch { console.log('catch!'); };
    }


    function handleFileCardClose(refresh) {
        if ((refresh) && (fieldid)) getFieldFiles(session, props.fieldid, setFiles);
        setShowFileCard(false);
    }





    function resizeBoundingBox(bounds) {
        var point1 = turf.point([bounds[0], bounds[1]]);
        var point2 = turf.point([bounds[2], bounds[3]]);
        var bearing = turf.bearing(point1, point2);
        var distance = turf.distance(point1, point2);
        var p1 = turf.destination(point1, -2 * distance, bearing);
        var p2 = turf.destination(point2, 2 * distance, bearing);
        var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
        return box;
    }







    function collectFeatures(feature) {
        console.log('collect');
        console.log(feature);
        if (!feature) return;
        collection.features.push(feature);
        console.log("expected " + expectFeatures + " got " + collection.features.length)
        if ((expectFeatures > 0) && (collection.features.length == expectFeatures))
            collectFinished();
    }


    function collectFinished() {
        console.log('finished');
        showGeoJson(collection, true);
        collection = emptyGeoJson;
    }






    function showGeoJson(feature, refresh) {
        if (!feature) return;

        let collection;
        try {
            if (feature.type == "FeatureCollection") {
                collection = feature;
            }
            else if (feature.type == "Feature") {
                collection = {
                    "type": "FeatureCollection",
                    features: [feature]
                }
            }
        } catch { console.log('unknown feature type'); }

        if (collection.features.length == 0) {
            console.log('No features to display');
            return;
        }

        console.log('Featrue Collection ready to display');
        setFeaturesCount(collection.features.length);

        let area = turf.area(feature) / 10000.0;
        let bounds = turf.bbox(feature);
        let center = turf.center(feature);
        let resizedbounds = resizeBoundingBox(bounds);

        //grid(resizedbounds, collection);
        //gridtest(resizedbounds, collection);

        applyFeatureCollection(collection);
        applyBoundingBox(resizedbounds);

        setArea(area.toFixed(2));
        setBBox(JSON.stringify(bounds));
        setShape(JSON.stringify(feature));

        if (refresh) setShowPreview(true);
    }



    function cancelClose() {
        //localStorage.setItem("lockvehicles", "false");
        console.log("close on send : " + closeOnSend);

        if (clearOnExit) {
            applyFeatureCollection(emptyGeoJson);
        }
        props.close(refreshAfterExit);
    }






    function selectFieldItem(e) {
        const idx = e.currentTarget.getAttribute("data-fieldindex");
        console.log('select field' + idx);
        /*
        for (let i=0;i<featureCollection.features.length;i++)
        {
            if (i==idx) { featureCollection.features[i].properties.value=7; }
                else { featureCollection.features[i].properties.value=5;  }
        }
        */
        console.log(featureCollection);
        setFeatureCollection(featureCollection);
        let feature = featureCollection.features[idx];
        let bounds = turf.bbox(feature);
        let resizedbounds = resizeBoundingBox(bounds);

        setBounds[bounds];
        applyBoundingBox(resizedbounds); //panel map box only
        applyFeatures(featureCollection);
        applyFocused(feature);
    }


    function selectLabItem(e) {
        const idx = e.currentTarget.getAttribute("data-labindex");

        /*
        for (let i=0;i<labFeatures.features.length;i++)
        {
            if (i==idx) { labFeatures.features[i].properties.value=10; }
                else { labFeatures.features[i].properties.value=9; }
        }
        */

        setLabFeatures(labFeatures);
        let feature = labFeatures.features[idx];
        let bounds = turf.bbox(feature);
        let resizedbounds = resizeBoundingBox(bounds);
        setBounds[bounds];
        applyBoundingBox(resizedbounds); //panel map box only
        applyLabFeatures(labFeatures);
        applyFocused(feature);
    }


    function getLastExam(examined) {
        console.log('get last exam');
        console.log(examined);
        examined.sort((a, b) => b.timestamp - a.timestamp); //sort descending
        return examined[0];

    }



    function RenderFieldFeatureList(param) {
        console.log('render params');
        console.log(param);

        if (!Array.isArray(param.features.features)) return;
        return (
            param.features.features.map(({ properties, area, examined, exam_percent }, index) => {
                var content = (index + 1).toString();
                if (properties.nazwa) content += "  Pole : " + properties.nazwa;
                else content += "  Pole bez nazwy "
                if (area) content += " ( " + area + " ha )";
                if (examined) {
                    console.log('examined');
                    console.log(examined);
                    var last = getLastExam(examined);

                    if (last.timestamp > 0) {
                        var date = new Date(last.timestamp);
                        content += " Przebadane " + date.getFullYear() + "-" + zeroPad(date.getMonth() + 1) + "-" + zeroPad(date.getDay());
                    }

                    if (last.percent > 0) content += " (" + last.percent + "%)";
                } //if examined
                    return (
                        <ListItem key={index} className='p-3 mx-0 text-sm' data-fieldindex={index} onClick={selectFieldItem}>
                            {content}
                        </ListItem>
                    )
                
            } //map
            )
        )
    }


    function RenderLabFeatureList(param) {

        if ((!param.features) || (!Array.isArray(param.features.features))) return (
            <div></div>
        )

        return (
            param.features.features.map(({ properties, area, assigned_to }, index) => {
                var content = (index + 1).toString();
                content += " obszar "
                if (area) content += " ( " + Math.round(area / 100) / 100 + " ha )";
                if (assigned_to) content += " [pole nr " + assigned_to + "]";
                return (
                    <ListItem key={index} className='p-3 mx-0 text-sm' data-labindex={index} onClick={selectLabItem}>
                        {content}
                    </ListItem>
                )
            }
            )
        )
    }


    function RenderFilesList(params) {
        if (params.files.length > 0)
            return (

                <div className='w-full mx-[0.5rem] my-1 flex flex-wrap gap-2'>
                    {results.map(({ id, title, filename, extension }) => {

                        console.log(filename, extension);

                        return (
                            <div className="flex flex-col gap-1 rounded-md w-[6rem] max-h-[8rem] bg-teal-50/50" data-fileid={id} onClick={selectFile}>

                                <DocumentIcon extension={extension} className='h-8 w-10 mx-auto my-1 flex' innerClassName="pl-2"></DocumentIcon>

                                <div className='line-clamp-2'>
                                    <Typography variant="small" label="Tytuł" className="text-center mx-auto font-normal text-xs text-blue-500" >{title}</Typography>
                                </div>

                            </div>
                        )
                    })}
                </div>

            )
        else return (<></>);
    }





    return (

        <div className='fixed w-full h-full top-0 left-0 items-center justify-center m-auto z-[10] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-auto max-w-[90vw] mx-auto rounded-lg shadow-lg max-h-[94vh] " shadow={true}>

                <div className="flex justify-between gap-2 w-auto mt-2">
                    <Typography color="gray" className="mt-0 pl-12 font-normal mx-auto">
                        Przetwarzanie wyników badań
                    </Typography>
                    <IconButton variant='text' className='-top-1 right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>
                <div className="w-auto flex flex-row items-start gap-2 m-2">


                    <div id="list of client features" className='w-[16rem] max-h-[79vh] overflow-y-auto overflow-x-hidden border border-green-100 rounded-lg'>

                        <div className="flex justify-between w-auto bg-green-100 rounded-lg ">
                            <Typography color="Black" className="mt-0 p-2 font-normal mx-auto text-center">
                                Pola z wybranej grupy
                            </Typography>
                        </div>

                        <List className='p-0 mx-0 w-full '>
                            <RenderFieldFeatureList features={featureCollection} />
                        </List>

                    </div>



                    <div id="map" className='rounded-lg p-1 bg-blue w-auto h-auto'>
                        <div id="list of imported files" className='flex flex-row mb-4 h-auto rounded-lg border border-green-100'>
                            <RenderFilesList files={results}></RenderFilesList>
                        </div>
                        <div id="buttons" className='flex flex-row mb-4 h-auto rounded-lg border border-blue-100 p-2'>
                            <Typography color="black" className="py-1 my-auto font-normal ml-2 mr-auto text-sm text-center">
                                {
                                    (examDate) && "Data badania : " + examDate
                                }
                                {
                                    (!labFile) && "Wybierz plik badań "
                                }

                            </Typography>

                            <Button className="flex items-center gap-2 mr-2 ml-auto" size="sm" color="yellow" disabled={!fileIsSelected} onClick={processResults}>
                                <BeakerIcon strokeWidth={2} className="h-4 w-4" />Przetwórz wyniki badań
                            </Button>

                            <Button className="flex items-center gap-2 mr-2 ml-2" size="sm" color="green" disabled={sendDisabled} onClick={saveProcessResults}>
                                <InboxIcon strokeWidth={2} className="h-4 w-4" />Zapisz wyniki
                            </Button>

                            <IconButton variant='gradient' className='ml-2 w-8 h-8' color="red" onClick={() => setShowConfirmClear(true)}>
                                <TrashIcon className='w-4 h-4' />
                            </IconButton>

                        </div>

                        <div id="map" className='rounded-lg border border-green-100'>
                            <MapboxPanel features={featureCollection} labFeatures={labFeatures} bounds={bounds} min={0} max={10} selected="value" style={{ position: 'relative', width: '50vw', height: '62vh', cornerRadius: "20px", margin: "5px" }} />
                        </div>
                    </div>



                    <div id="list of lab results features" className='w-[16rem] max-h-[79vh] overflow-y-auto overflow-x-hidden border border-green-100 rounded-lg'>
                        <div className="flex justify-between w-auto bg-green-100 rounded-lg ">
                            <Typography color="black" className="mt-0 p-2 font-normal mx-auto text-center">
                                {
                                    (!labFile) && "Wybierz badanie do importu"
                                }
                                {
                                    (labFeatures.features) && "Wyniki badań (" + labFeatures.features.length + ")"
                                }

                            </Typography>
                        </div>

                        <List className='p-0 mx-0 w-full'>
                            <RenderLabFeatureList features={labFeatures} />
                        </List>

                    </div>

                </div>
            </Card>
            <ConfirmDialog open={showConfirmClear} message="Czy na pewno chcesz usunąć informacje o badaniach wybranej grupy pól ?" onCancel={() => setShowConfirmClear(false)} onConfirm={clearAllResults} />
        </div>

    );

}



