import React, { useState } from "react";
import  {Navigate, useNavigate } from 'react-router-dom';


import { ClientNavbar } from './ClientNavBar';
import { Button } from '@material-tailwind/react';
import { Typography } from '@material-tailwind/react'
import { Sidebar } from './Sidebar';
import MapBox from './MapBox';

import AlertCard from "./Alert";

export default function Admin() {
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [rank, setRank] = useState(localStorage.getItem("rank") || 0);
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
  const navigate = useNavigate();

  const mapBoxReference = React.useRef();
  var featureCollection = {
    "type": "FeatureCollection",
    features: []
  }
  
  React.useEffect(() => {
    
    if ((!session) || (rank!=10)) navigate('/')  
    

  }, []);

  if (authenticated) {
    return (
      
      <div className='h-screen w-screen fixed'>
        
        <div >
        <MapBox mapReference={mapBoxReference} features={featureCollection}/>
        </div>

        <Sidebar mapReference={mapBoxReference} features={featureCollection}/> 
       
        <AlertCard />
      </div>
      
    );
  }
  else 
  {
    return <Navigate to={"/"} replace />;
  }
  }
  
 
  