import * as turf from '@turf/turf'

export function resizeBoundingBox(bounds) {
    var point1 = turf.point([bounds[0], bounds[1]]);
    var point2 = turf.point([bounds[2], bounds[3]]);
    var bearing = turf.bearing(point1, point2);
    var distance = turf.distance(point1, point2);
    var p1 = turf.destination(point1, -0.2 * distance, bearing);
    var p2 = turf.destination(point2, 0.2 * distance, bearing);
    var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
    return box;
}


export function applyFeatrueCollection(collection) {
    localStorage.setItem('featureCollection', JSON.stringify(collection));
    dispatchEvent(new Event("featureCollectionChanged"));
}

export function applyBoundingBox(bounds) {
    localStorage.setItem('boundingBox', JSON.stringify(bounds));
    dispatchEvent(new Event('boundingBoxChanged',bounds));
}


export function showFields(fields) {
    let features = [];
    fields.forEach(field => {
        let shape=JSON.parse(field.shape);
        if (shape.type=="feature") features.push(shape);
        if (shape.type=="FeatureCollection") {
            shape.features.forEach(feature => features.push(feature));
        } 
        console.log('shape');
        console.log(shape);
        features.push();
    });

    showFeatures(features);
}


export function focusOnFeature(feature) {
    if (!feature) return;
    if (feature.type!="Feature") 
        {
            console.log('this is not feature!');
            return;
        }

    let bounds = turf.bbox(feature);
    let resizedbounds= resizeBoundingBox(bounds);

    applyBoundingBox(resizedbounds);
}


export function showFeature(feature) {
    if (!feature) return;
    if (feature.type!="Feature") 
        {
            console.log('this is not feature!');
            return;
        }

    let collection = {
        "type": "FeatureCollection",
        features: [feature]
    }

    let bounds = turf.bbox(collection);
    let resizedbounds= resizeBoundingBox(bounds);
    
    applyFeatrueCollection(collection);
    applyBoundingBox(resizedbounds);
}




export function showFeatures(features) {
    if (!Array.isArray(features)) 
    {
        console.log('Array of Featrues was expected!');
        return;
    }
    if (features.length==0) 
    {
        console.log('Empty Array of Features!');
        return;
    }

    let collection = {
        "type": "FeatureCollection",
        features: features
    }
    try 
    {
    console.log('collection');
    console.log(collection);
    let bounds = turf.bbox(collection);
    let resizedbounds= resizeBoundingBox(bounds);
    
    applyFeatrueCollection(collection);
    applyBoundingBox(resizedbounds);
    } catch { console.log('error at showFeatures'); }
}


export function focusOnFeatures(features) {
    if (!Array.isArray(features)) 
    {
        console.log('Array of Featrues was expected!');
        return;
    }
    if (features.length==0) 
    {
        console.log('Empty Array of Features!');
        return;
    }

    let collection = {
        "type": "FeatureCollection",
        features: features
    }

    let bounds = turf.bbox(collection);
    let resizedbounds= resizeBoundingBox(bounds);
    
    applyBoundingBox(resizedbounds);
}


export function showGeoJson(feature) {
    if (!feature) return;
    console.log('feature');
    console.log(feature);

    let collection = {
        "type": "FeatureCollection",
        features: [feature]
    }

    console.log('collection');
    console.log(collection);

    let area = turf.area(feature) / 10000.0;
    let bounds = turf.bbox(feature);

    let center = turf.center(feature);
    let resizedbounds= resizeBoundingBox(bounds);
    
    applyFeatrueCollection(collection);
    applyBoundingBox(resizedbounds);

}


export function matchFieldsResults(fields, results, timestamp, fileid, callback) {

    for (var j=0; j<results.features.length;j++)
        {
            results.features[j].area=turf.area(results.features[j]); //in square meters (m2)
        }


    for (var i=0; i<fields.features.length;i++)  {
      fields.features[i].area=turf.area(fields.features[i]); //in square meters (m2)
      fields.features[i].exam_area=0;  
        for (var j=0; j<results.features.length;j++)
        {
            var intersection = turf.intersect(fields.features[i], results.features[j]); 
                if (intersection)       
                {
                    fields.features[i].exam_area+=results.features[j].area;
                    results.features[j].assigned_to=i+1;
                }
        } //for j
        var examvalue=fields.features[i].exam_area/fields.features[i].area;
        if (examvalue>=0.999) examvalue=1.0;
        var exam_percent = Math.round(examvalue*10000)/100;
        fields.features[i].exam_percent = exam_percent;
        fields.features[i].area=Math.round(fields.features[i].area/100)/100;
        
        let examobj={ fileid: fileid, timestamp: timestamp, percent: exam_percent };
        if (examvalue>0.900) 
        {
            if (!Array.isArray(fields.features[i].examined)) fields.features[i].examined=[examobj];
            else
            {
                if (!fields.features[i].examined.some(e => e.timestamp === timestamp)) {
                    fields.features[i].examined.push(examobj);
                    console.log('not contains');
                }
                else
                {
                    console.log('contains');
                }
                //if (!fields.features[i].examined.timestamp.includes(timestamp)) fields.features[i].examined.push(examobj);
            }
        }
        delete fields.features[i].exam_area;
        
    } //for i
    if (callback) callback(fields,results);



}