import React, { useState } from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    IconButton,
    Button,
    Tooltip,
    ListItem,
    ListItemPrefix,
    Typography,


} from "@material-tailwind/react";
import {
    PencilSquareIcon,
    UserIcon,
    FolderPlusIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";


import OfferCard from './OfferCard';
import { getOffers } from './AdminTools';


export default function OffersList(props) {
    const [crew, setCrew] = useState([]);
    const [offerid, setOfferId] = useState(0);

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('');
    const [showOfferCard, setShowOfferCard] = useState(false);


    React.useEffect(() => {
        
    }, []);


    function cancelClose() {
        props.Close();
    }


    const handleShowOfferCard = (e) => {
        const id = e.currentTarget.getAttribute("data-offerid");
        setOfferId(id);
        setShowOfferCard(showOfferCard === true ? false : true);
    };



    
    function showHideCard(refresh) {
        setShowOfferCard();
        if (refresh == true) getOffers(session,setOffers);
    }

    



    return (
        <>
            <Card className="absolute my-auto top-[8rem] w-auto p-2 shadow-xl lg:left-[17rem] left-[6rem] lg:left-[17rem] left-[6rem] z-10 overflow-y-auto shrink">
                        <IconButton variant='text' className='top-2 absolute right-2 ml-auto' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-5 h-5' />
                       </IconButton>

                <CardHeader floated={false} shadow={false} className="rounded-none mr-12">
                    <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                        <div>
                            <Typography variant="h6" color="blue-gray">
                                Zestawienie ofert 
                            </Typography>
                            <Typography color="gray" className="mt-1 font-normal">
                                Klienci mogą przygotować zlecenia do wskazanych ofert
                            </Typography>
                        </div>
                        <div className="flex w-full shrink-0 gap-2 md:w-max">
                            <Button className="flex items-center gap-3" size="sm" color="green" data-offerid={0} onClick={handleShowOfferCard}>
                                <FolderPlusIcon strokeWidth={2} className="h-4 w-4" /> Nowa oferta
                            </Button>
                        </div>

                    </div>
                </CardHeader>
                <CardBody className="overflow-auto px-0">
                    <table className="w-full table-auto text-left">
                        <thead>
                            <tr>
                                <th key="nazwa" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70">Nazwa badania</Typography>
                                <Typography variant="small" color="blue-gray" className="font-normal opacity-70">(widoczna dla klienta)</Typography>
                                </div>
                                </th>
                                <th key="labnazwa" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70">Znacznik badania</Typography>
                                <Typography variant="small" color="blue-gray" className="font-normal opacity-70">(dla laboratorium)</Typography>
                                </div>
                                </th>
                                <th key="cena" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70">Cena badania</Typography>
                                <Typography variant="small" color="blue-gray" className="font-normal opacity-70">(za obszar)</Typography>
                                </div>
                                </th>
                                <th key="uwagi" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <div className='flex flex-col items-center'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 hidden md:block">Uwagi</Typography>
                                </div>
                                </th>
                                <th key="edit" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {offers && offers.map(
                                (
                                    {
                                        id,
                                        name,
                                        labname,
                                        price,
                                        description,
                                    },
                                    index,
                                ) => {
                                    const isLast = index === offers.length - 1;
                                    const classes = isLast ? "p-2" : "p-2";

                                    return (
                                        <tr key={name} className='even:bg-blue-gray-50/50'>
                                            <td className={classes}>
                                                <div className="flex items-center gap-1">
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {name}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {labname}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                    {price}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                            <Typography variant="small" color="blue-gray" className="font-normal hidden md:block">
                                                    {description}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Tooltip content="Popraw ofertę">
                                                    <IconButton variant="gradient" size="md" data-offerid={id} onClick={handleShowOfferCard}> 
                                                        <PencilSquareIcon className="h-5 w-5" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>

            ${showOfferCard === true ? <OfferCard close={showHideCard} id={offerid} /> : ''}
        </>
    );
}

