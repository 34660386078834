



export function getCompanies(session, userid, callback) {
  var bodydata;
  console.log('get my companies');
  bodydata = JSON.stringify({ action: 'companies', sid: session});
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/user.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log('got data');
      console.log(data);
      if (data.companies) callback(data.companies);
    })
    .catch(error => {
      console.log({ errorMessage: error.toString() });
      console.error('There was an error (comapnies)!', error);
  });

}


export function getNews(session, callback) {
  var bodydata;
  bodydata = JSON.stringify({ action: 'list', sid: session});
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/news.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log('got data');
      console.log(data);
      if (data.news) callback(data.news);
    })
    .catch(error => {
      console.log({ errorMessage: error.toString() });
      console.error('There was an error (news)!', error);
  });

}



export function getFields(session, userid, setFields, addFields) {
  var bodydata;
  console.log('get fields of owner ' + userid);
  bodydata = JSON.stringify({ action: 'list', sid: session, owner: userid });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/fields.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'

  })
    .then(res => res.json())
    .then(data => {
      console.log('got data');
      console.log(data);

      if (data.fields) {
        if (addFields) setFields([...data.fields]); //add
        if (setFields) setFields(data.fields); //set

        localStorage.setItem("fields", JSON.stringify(data.fields))
      };
    })
    .catch(error => {
      console.log({ errorMessage: error.toString() });
      console.error('There was an error (fields)!', error);
  });

}




export function getOrders(session, userid, setOrders, addOrders) {
  var bodydata;
  console.log('get orders of owner ' + userid);
  bodydata = JSON.stringify({ action: 'list', sid: session, owner: userid });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/orders.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'

  })
    .then(res => res.json())
    .then(data => {
      console.log('got orders data');
      console.log(data);
      if (data.status=="ok")
      {
      if (data.orders) {
        if (setOrders) setOrders(data.orders);
        if (addOrders) setOrders(...data.orders);
        localStorage.setItem("orders", JSON.stringify(data.orders))
      };
      }
    })
    .catch(error => {
      console.log({ errorMessage: error.toString() });
      console.error('There was an error (orders)!', error);
    });


}



export function getMyDocuments(session, owner, setDocuments) {
  var bodydata = JSON.stringify({ action: 'myfiles', session: session, owner: owner  });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/files.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      if (data.status == "ok") {
        setDocuments(data.files);
        if (data.files) {
          localStorage.setItem("documents", JSON.stringify(data.files))
        };
      }
    })
    .catch(error => {
      console.log({ errorMessage: error.toString() + "docs" });
      console.error('There was an error (documents)!', error);
    });

}



function base64ToArrayBuffer(base64) {
  var binary_string =  window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array( len );
  for (var i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}


export function downloadDocument(session, id) {
  var bodydata = JSON.stringify({ action: 'download', session: session, id: id});
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/files.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      if (data.status == "ok") {
        
        let blob = new Blob([base64ToArrayBuffer(data.files[0].binarydata)]);
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = data.files[0].filename;
        alink.click();
        window.URL.revokeObjectURL(fileURL);
      }
    })
}



export function getSamplers(session, setSamplers) {
  var bodydata = JSON.stringify({ action: 'samplers', session: session });
  console.log(bodydata);
  fetch('https://agro-online.pl/cgi-bin/samplers.py', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: bodydata,
    mode: 'cors'
  })
    .then(res => res.json())
    .then(data => {
      console.log(data);
      if (data.status == "ok") {
        //if (data.samplers.length==0) return;
        setSamplers(data.samplers);
        if (data.samplers) {
          localStorage.setItem("vehicles", JSON.stringify(data.samplers)) // yes vehicles to be compatible with admin markers
        };
      }
    })
}
