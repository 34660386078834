import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
    Alert,
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    DialogHeader,
    DialogFooter,
    DialogBody,

} from "@material-tailwind/react";

import {
    FolderMinusIcon,
    ExclamationTriangleIcon,
    XMarkIcon, EnvelopeIcon,
    ChatBubbleLeftIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';

import { showAlert } from './Alert.jsx';


export default function CompanyCard(props) {

    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalcode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [nip, setNip] = useState('');
    const [krs, setKrs] = useState('');
    const [action, setAction] = useState('create');
    const [id, setId] = useState(0);
    const [master, setMaster] = useState(0);

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [confirm,setConfirm] = useState(false);

    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend,setCloseOnSend] = useState(false);


    React.useEffect(() => {
        console.log('company props');
        console.log(props);

        if (Array.isArray(clients)) {
            console.log('company is array');
            var company = clients.filter(c => c.id === Number(props.id))[0];
            if (company) {
                console.log(company);
                setId(Number(props.id));
                setAction('update');
                setCompanyName(company.name);
                setEmail(company.email);
                setPostalCode(company.postalcode);
                setCity(company.city);
                setAddress(company.address);
                setNip(company.nip);
                setKrs(company.krs);
                setMaster(company.master);
            }
            else
            {
                setMaster(props.master);
            }
        
        }

    }, []);


    function cancelClose() {
        props.close(refreshAfterExit);
    }

    

    function handleDataResponse(data) {

        console.log(data)
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);
            if (closeOnSend) { props.close(refreshAfterExit); }

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        var bodydata = JSON.stringify({ action: action, session: session, login: '', password: '', name: companyName, firstname: '', lastname: '', email: email, phone: phone, postalcode: postalcode, city: city, nip: nip, krs: krs, address: address, newrank: 1, id: id, master:master });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/user.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }

    function deleteCompany() {
        
        var bodydata = JSON.stringify({ action: 'remove', session: session, login: '', password: '', name: companyName, id: id, master:master });
        console.log(bodydata);
        setCloseOnSend(true);
        fetch('https://agro-online.pl/cgi-bin/user.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }


    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto z-[20] flex place-content-center bg-black/50'>

            <Card color="white" className="w-full max-w-[28rem] mx-auto py-4 z-20 max-h-[94vh] overflow-y-auto" shadow={true}>

                <div className="flex items-center justify-between gap-4 w-auto my-2">
                    <Typography color="gray" className="mt-2 pl-12 font-normal mx-auto">
                        Karta danych spółki
                    </Typography>
                    <IconButton variant='text' className='top-[-1rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>
                <form className="mt-4 mb-0 mx-auto w-full tems-center justify-center py-4 px-4" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-6 w-auto items-center justify-center ">
                        <Input size="md" label="Pełna nazwa firmy" value={companyName} className="shadow-md mx-auto" onChange={e => setCompanyName(e.target.value)} />
                        <div className="flex items-center w-full px-16">
                        <Input size="md" label="Adres (ulica z numerem domu)" className="shadow-md" value={address} onChange={e => setAddress(e.target.value)} />
                        </div>
                        <div className="flex items-center gap-4 px-4 ">
                            <Input size="md" label="Kod pocztowy" className="shadow-md w-32" value={postalcode} onChange={e => setPostalCode(e.target.value)} />
                            <Input size="md" label="Miasto" className="shadow-md" value={city} onChange={e => setCity(e.target.value)} />
                        </div>
                        <div className="flex items-center gap-4 px-4 mx-4">
                            <Input size="md" label="NIP" className="shadow-md" value={nip} onChange={e => setNip(e.target.value)} />
                            <Input size="md" label="KRS" className="shadow-md" value={krs} onChange={e => setKrs(e.target.value)} />
                        </div>

                        <Input size="md" label="Email" className="shadow-md" value={email} onChange={e => setEmail(e.target.value)} />

                        <div className='flex flex-row justify-between w-full mt-4' >
                            <IconButton variant='gradient' className='ml-0' color="red" data-refresh={false} onClick={() => setConfirm(true)} >
                                <TrashIcon className='w-6 h-6' />
                            </IconButton>
                            <Button className="items-center mx-auto w-40 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 " data-refresh={true} fullWidth type="submit">
                                Zapisz
                            </Button>
                        </div>
                    </div>

                </form>
            </Card>
            
            <Dialog open={confirm} size={"xs"} handler={setConfirm}>
                <DialogHeader>Jesteś pewien ?</DialogHeader>
                <DialogBody divider>
                    Czy na pewno chcesz usunąć tą spółkę
                </DialogBody>
                <DialogFooter>
                    <Button variant="text" color="red" onClick={() => setConfirm(false)} className="mr-8">
                        <span>Nie</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => deleteCompany()}>
                        <span>Tak</span>
                    </Button>
                </DialogFooter>
            </Dialog>

        </div>
    );
}

