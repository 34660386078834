import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
    Alert,
    Card,
    Dialog,
    DialogHeader,
    DialogFooter,
    DialogBody,
    Input,
    Checkbox,
    List,
    ListItem,
    ListItemSuffix,
    Select,
    Option,
    Button,
    Typography,
    IconButton,
    Tooltip
} from "@material-tailwind/react";

import {
    CheckCircleIcon,
    PlusCircleIcon,
    EllipsisHorizontalCircleIcon,
    FolderMinusIcon,
    ExclamationTriangleIcon,
    XMarkIcon, EnvelopeIcon,
    ChatBubbleLeftIcon,
    BriefcaseIcon,
    SquaresPlusIcon,
    TrashIcon,
    PencilSquareIcon,
} from '@heroicons/react/24/outline';


import SmsCard from './SmsCard';
import CompanyCard from './CompanyCard';
import AgroTechnologyLogo from './agro-small.jpg';
import { getClients } from './AdminTools';
import { showAlert } from './Alert.jsx';

export default function ClientCard(props) {

    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalcode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [nip, setNip] = useState('');
    const [krs, setKrs] = useState('');
    const [action, setAction] = useState('create');
    const [id, setId] = useState(0);
    const [hasCompanies, setHasCompanies] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(); //id of selected or edited company


    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [confirm, setConfirm] = useState(false);

    const [refreshAfterExit, setRefreshAfterExit] = useState(false);

    const [showSmsCard, setShowSmsCard] = useState(false);
    const [showCompanyCard, setShowCompanyCard] = useState(false);


    React.useEffect(() => {
        if (Array.isArray(clients)) {
            var client = clients.filter(c => c.id === Number(props.id))[0];
            if (client) {
                console.log(client);
                setId(Number(props.id));
                setAction('update');
                setName(client.name);
                setFirstName(client.firstname);
                setLastName(client.lastname);
                setEmail(client.email);
                setPhone(client.phone);
                setPostalCode(client.postalcode);
                setCity(client.city);
                setAddress(client.address);
                setLogin(client.login);
                setNip(client.nip);
                setKrs(client.krs);
                pickCompanies(Number(props.id));
                //setMaster(client.master);
            }

        }
    }, []);



    function pickCompanies(userid) {
        let comps = clients.filter(c => c.master === userid);
        setHasCompanies(comps.length > 0);
        setCompanies(comps);
        console.log('companies');
        console.log(comps);
    }

    function getClientsCallback() {
        pickCompanies(id);
    }

    function cancelClose() {
        props.close(refreshAfterExit);
    }


    function handleShowSmsCard() {
        setShowSmsCard(!showSmsCard);
    }

    function handleCloseCompanyCard(doRefresh) {
        setShowCompanyCard(false);
        if (doRefresh) getClients(session, setClients, getClientsCallback);
    }


    function handleEditCompany(e) {
        console.log('handle company id' + e);
        setCompany(e);
        setShowCompanyCard(true);
    }

    function handleSelectCompany(e) {
        console.log('selected company id' + e);
        setCompany(e);
    }


    function handleDataResponse(data) {

        console.log(data)
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            //props.close(true);
            setRefreshAfterExit(true);
        }

        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }

    function handleSubmitClient(e) {
        e.preventDefault();
        var bodydata = JSON.stringify({ action: action, session: session, login: login, password: password, name: name, firstname: firstname, lastname: lastname, email: email, phone: phone, postalcode: postalcode, city: city, nip: nip, krs: krs, address: address, newrank: 1, id: id });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/user.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
            .catch(error => {
                console.log({ errorMessage: error.toString() });
                console.error('There was an error!', error);
            });

    }


    function deleteClient() {
        let bodydata = JSON.stringify({ action: 'remove', session: session, id: id, login: login });
        console.log(bodydata);

        setConfirm(false);

        fetch('https://agro-online.pl/cgi-bin/user.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
            .catch(error => {
                console.log({ errorMessage: error.toString() });
                console.error('There was an error!', error);
            });

    }



    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto flex place-content-center'>

            <Card color="white" className="w-full max-w-[30rem] mx-auto py-4 z-20 max-h-[94vh] overflow-y-auto" shadow={true}>

                <div className="flex items-center justify-between gap-4 w-auto my-2">
                    <Typography color="gray" className="mt-2 pl-12 font-normal mx-auto">
                        Karta danych klienta
                    </Typography>
                    <IconButton variant='text' className='top-[-1rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>
                <form className="mt-4 mx-auto w-80 items-center justify-center p-2" onSubmit={handleSubmitClient}>
                    <div className="mb-6 flex flex-col gap-3 w-auto items-center justify-center ">
                        <Input size="md" label="Nazwa klienta (może być nazwa firmy)" value={name} className="shadow-md max-w-[20rem] mx-auto" onChange={e => setName(e.target.value)} />
                        <div className="flex items-center gap-4 w-auto my-2">
                            <Input size="md" label="Imię" className="shadow-md w-auto" value={firstname} onChange={e => setFirstName(e.target.value)} />
                            <Input size="md" label="Nazwisko" className="shadow-md w-auto" value={lastname} onChange={e => setLastName(e.target.value)} />
                        </div>
                        <Input size="md" label="Email" className="shadow-md" value={email} onChange={e => setEmail(e.target.value)} />

                        <div className="flex items-center gap-4 w-auto mx-8 my-2">
                            <Input size="md" label="Telefon kontaktowy" className="shadow-md" value={phone} onChange={e => setPhone(e.target.value)} />
                            <Tooltip content="Wyślij SMS">
                                <IconButton variant='gradient' size="md" className='p-4' color="blue" data-refresh={false} onClick={handleShowSmsCard}>
                                    <ChatBubbleLeftIcon className='w-5 h-5' />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className="flex items-center w-auto my-2">
                            {companies.length > 0 &&
                                <>

                                    <Select label="Spółki zależne" onChange={handleSelectCompany}>
                                        {
                                            companies.map((c) => (
                                                <Option key={c.id} value={c.id.toString()}>{c.name}</Option>
                                            ))
                                        }
                                    </Select>
                                    <Tooltip content="Dodaj spółkę">
                                        <IconButton variant='gradient' size="md" className='p-4 ml-3' color="green" data-refresh={false} onClick={() => handleEditCompany(0)}>
                                            <PlusCircleIcon className='w-5 h-5' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip content="Edytuj">
                                        <IconButton variant='gradient' size="md" className='p-4 ml-1' color="blue" data-refresh={false} onClick={() => handleEditCompany(company)}>
                                            <PencilSquareIcon className='w-5 h-5' />
                                        </IconButton>
                                    </Tooltip>

                                </>
                            }

                            {companies.length == 0 &&
                                <Button variant='gradient' size="md" className='flex items-center gap-2 px-3 w-auto' color="green" onClick={() => handleEditCompany(0)}>
                                    <BriefcaseIcon className='w-5 h-5' />
                                    Dodaj spółkę
                                </Button>
                            }
                        </div>

                        <div className="flex items-center gap-4 mb-2">
                            <Input size="md" label="Kod pocztowy" className="shadow-md" value={postalcode} onChange={e => setPostalCode(e.target.value)} />
                            <Input size="md" label="Miasto" className="shadow-md" value={city} onChange={e => setCity(e.target.value)} />
                        </div>

                        <Input size="md" label="Ulica (z numerem domu)" className="shadow-md " value={address} onChange={e => setAddress(e.target.value)} />

                        <div className="flex items-center gap-4 mt-2">
                            <Input size="md" label="Login (może być email)" className="shadow-md" value={login} onChange={e => setLogin(e.target.value)} />
                            <Input size="md" label="Hasło" className="shadow-md" onChange={e => setPassword(e.target.value)} />

                        </div>
                        <span color="gray" variant="small" className="w-48 relative text-xs font-normal ml-[14rem] top-[-10px] my-0 justify-center text-center">Pozostaw to pole puste, jesli nie chcesz zmienić hasła.</span>

                        <div className='flex flex-row justify-between w-full mt-4' >
                            <IconButton variant='gradient' className='ml-0 right-14' color="red" data-refresh={false} onClick={() => setConfirm(true)} >
                                <TrashIcon className='w-6 h-6' />
                            </IconButton>
                            <Button className="items-center mx-auto w-40 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 " data-refresh={true} fullWidth type="submit">
                                Zapisz
                            </Button>
                        </div>

                    </div>

                </form>
            </Card>


            {showSmsCard && <SmsCard close={handleShowSmsCard} name={name} phone={phone} />}
            {showCompanyCard && <CompanyCard close={handleCloseCompanyCard} id={company} master={id} companies={companies} />}

            <Dialog open={confirm} size={"xs"} handler={setConfirm}>
                <DialogHeader>Jesteś pewien ?</DialogHeader>
                <DialogBody divider>
                    Czy na pewno chcesz usunąć tego klienta
                </DialogBody>
                <DialogFooter>
                    <Button variant="text" color="red" onClick={() => setConfirm(false)} className="mr-8">
                        <span>Nie</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => deleteClient()}>
                        <span>Tak</span>
                    </Button>
                </DialogFooter>
            </Dialog>

        </div>
    );
}

