import React, { useState, useRef } from 'react';
import { UseMap } from 'react-map-gl';


import {
    Alert,
    Badge,
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Select,
    Option,
    Textarea,
    Tooltip

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    ArrowTopRightOnSquareIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    PencilIcon,
    TrashIcon,
    SparklesIcon,
    RectangleGroupIcon,
    FolderPlusIcon,
    //DocumentIcon,
    DocumentChartBarIcon,
    PaperClipIcon,
    FunnelIcon,

} from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ShapeUnziper from './ShapeUnziper.jsx';

import * as turf from '@turf/turf'

import MapboxPanel from './MapBoxPanel.jsx';
import { json } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import ConfirmDialog, { QueryDataDialog } from './ConfirmDialog.jsx';
import { showAlert } from './Alert.jsx';
import { geoportalGetParcelPolygon } from './geoportal.js';
import FileUploadCard from './FileUploadCard.jsx';
import { getFieldFiles } from './AdminTools.jsx';
import DocumentIcon from './DocumentIcon.jsx';


export default function DoubleFieldCard(props) {

    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem("files")) || []);


    const [ownerid, setOwnerId] = useState();
    const [clientName, setClientName] = useState('');
    const [shape, setShape] = useState();
    const [action, setAction] = useState('create');
    const [fieldid, setFieldId] = useState(0);
    const [results, setResults] = useState();


    const [clearOnExit, setClearOnExit] = useState(false);
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);

    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [showFileCard, setShowFileCard] = useState(false);



    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend, setCloseOnSend] = useState(false);

    const [splitBodyData, setSplitBodyData] = useState({});

    const emptyGeoJson = {
        "type": "FeatureCollection",
        features: []
    }

    let collection = emptyGeoJson;
    let collectionToSplit = null;
    let expectFeatures = 0;


    React.useEffect(() => {
        setOwnerId(props.clientid);
        setFieldId(props.fieldid);
        //init(props);
        localStorage.setItem("lockvehicles", "true");
        //if (props.fieldid) getFieldFiles(session, props.fieldid, updateFiles,"json");   //// important    

    }, []);


    React.useEffect(() => {
        let res = files.filter(f => f.category == "shapedata");
        setResults(res);
    }, [files]);

    function updateFiles(files) {
        setFiles(files);
        let results = files.filter(f => f.category == "shapedata");
        setResults(results);

    }


    function init(props) {
        if (props.fieldid > 0) {
            setAction('update');

            let fieldarray = fields.filter(f => f.id == props.fieldid);
            if (fieldarray.length == 1) {
                let field = fieldarray[0];
                setOwnerId(field.owner);
                setName(field.name);
                setCrops(field.crops);
                setDescription(field.description);
                setArea(field.area);


                if (field.shape) {
                    //add a bit of delay for better rendering
                    //showGeoJson(JSON.parse(field.shape), false);
                }
            }
        }
    }


    function handleFileCardClose(refresh) {
        if ((refresh) && (fieldid)) getFieldFiles(session, props.fieldid, setFiles);
        setShowFileCard(false);
    }




    function resizeBoundingBox(bounds) {
        var point1 = turf.point([bounds[0], bounds[1]]);
        var point2 = turf.point([bounds[2], bounds[3]]);
        var bearing = turf.bearing(point1, point2);
        var distance = turf.distance(point1, point2);
        var p1 = turf.destination(point1, -0.2 * distance, bearing);
        var p2 = turf.destination(point2, 0.2 * distance, bearing);
        var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
        return box;
    }


    function applyFeatureCollection(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        //dispatchEvent(new Event("featureCollectionChanged"));
    }

    function applyBoundingBox(bounds) {
        //localStorage.setItem('boundingBox', JSON.stringify(bounds));
        //dispatchEvent(new CustomEvent('boundingBoxChanged', { detail: bounds })); //cool can pass arguments in event this way
    }

    function applyMinMax(min,max) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { min: min, max: max } }));
    }




    function collectFeatures(feature) {
        console.log('collect');
        console.log(feature);
        if (!feature) return;
        collection.features.push(feature);
        console.log("expected " + expectFeatures + " got " + collection.features.length)
        if ((expectFeatures > 0) && (collection.features.length == expectFeatures))
            collectFinished();
    }

    function collectFinished() {
        console.log('finished');
        showGeoJson(collection, true);
        collection = emptyGeoJson;
    }


    function showGeoJson(feature, refresh) {
        if (!feature) return;

        let collection;
        try {
            if (feature.type == "FeatureCollection") {
                collection = feature;
            }
            else if (feature.type == "Feature") {
                collection = {
                    "type": "FeatureCollection",
                    features: [feature]
                }
            }
        } catch { console.log('unknown feature type'); }

        if (collection.features.length == 0) {
            console.log('No features to display');
            return;
        }

        console.log('Featrue Collection ready to display');
        console.log(collection);
        setFeaturesCount(collection.features.length);

        let area = turf.area(feature) / 10000.0;
        let bounds = turf.bbox(feature);
        let center = turf.center(feature);
        let resizedbounds = resizeBoundingBox(bounds);

        //grid(resizedbounds, collection);
        //gridtest(resizedbounds, collection);

        applyFeatureCollection(collection);
        applyBoundingBox(resizedbounds);

        setArea(area.toFixed(2));
        setBBox(JSON.stringify(bounds));
        setShape(JSON.stringify(feature));

        if (refresh) setShowPreview(true);
    }



    function cancelClose() {
        localStorage.setItem("lockvehicles", "false");
        console.log("close on send : " + closeOnSend);

        if (clearOnExit) {
            applyFeatureCollection(emptyGeoJson);
        }
        props.close(refreshAfterExit);
    }



    var shpwrite = require("shp-write-update");

    function download_default() {
        var options = {
            folder: 'granice',
            types: {
                point: 'points',
                polygon: 'granice',
                polyline: 'lines'
            },
            file: 'granice'

        }
        // a GeoJSON bridge for features
        let parsed = JSON.parse(shape);
        console.log('downloading shape');
        console.log(parsed);
        shpwrite.download(parsed, options);
    }




    function Panel(props) {
        const [featureCollection, setFeatureCollection] = useState(JSON.parse(localStorage.getItem("featureCollection")) || emptyGeoJson);
        const [labParams, setLabParams] = useState([]);
        const [selectedParam, setSelectedParam]= useState("value");
        const [min, setMin]= useState(1);
        const [max, setMax]= useState(5);
        const [polygonColor, setPolygonColor]=useState("#ffff80");

        React.useEffect(() => {
            
            //init(props);
            featureCollection.features.forEach(element => {
                element.properties.color = "#ffff80"; 
                element.properties.value = 3; 
            })
            setFeatureCollection(featureCollection);
    
        }, []);
    


        function getColor(value) {
            let color="#ffff80";
            if (selectedParam.startsWith('Ocena')) {
                switch (value) {
                    case 1 : color="#ff0000"; break;
                    case 2 : color="#ff8000"; break;
                    case 3 : color="#ffff00"; break;
                    case 4 : color="#80ff00"; break;
                    case 5 : color="#00ff00"; break;
                    case 6 : color="#00ff80"; break;
                    case 7 : color="#00ffff"; break;
                    case 8 : color="#0080ff"; break;
                    case 9 : color="#0000ff"; break;
                }
                
            }
            console.log(color);
            return '#ff0000';

        }




        function applyFeaturesColor() {
            console.log('apply');
            let _min=100, _max=0;
            
            featureCollection.features.forEach(element => {
                let value=element.properties[selectedParam];
                //element.properties.color = getColor(value); 
                element.properties.value = value; 
                if (value<_min) _min=value;
                if (value>_max) _max=value;
            })
            console.log('min ' + _min);
            console.log('max ' + _max);

            console.log(featureCollection);
            setFeatureCollection(featureCollection);
        }



        function parseLabPrams(collection) {
            let allkeys = [];
            collection.features.forEach(element => {
                let keys = Object.keys(element.properties);
                keys.forEach(key => {
                    if (!allkeys.includes(key)) allkeys.push(key);
                })
            });
            if (allkeys.includes('gpsnr')) allkeys.splice(allkeys.indexOf('gpsnr'), 1);
            setLabParams(allkeys);
            if (allkeys.length>0) setSelectedParam(allkeys[0]);
        }


        const selectFile = (e) => {
            const fileid = e.currentTarget.getAttribute("data-fileid");
            let file = files.filter(f => f.id == fileid)[0];
            try
            {
            let collection = JSON.parse(file.jsondata);
            setFeatureCollection(collection);
            parseLabPrams(collection);

            } catch {};
        };


        function handleParamChange(e) {
            let newparam=e.currentTarget.getAttribute("data-param");
            setSelectedParam(newparam);
            applyFeaturesColor();
            
            if (newparam.startsWith('Ocena')) {
                setMin(1);
                setMax(5);
            }
            else
            {
                if (newparam=="Mg") {
                    setMin(5);
                    setMax(25);
                }
                if (newparam=="K2O") {
                    setMin(5);
                    setMax(25);
                }
                if (newparam=="P2O5") {
                    setMin(1);
                    setMax(35);
                }
                if (newparam.startsWith('ph')) {
                    setMin(3);
                    setMax(11);
                }
    

            }
            //applyMinMax(min,max);
            console.log(featureCollection);
        }


        return (
            <div className='mx-auto border rounded-lg bg-gray-50'>
                {files.length > 0 &&
                    <div className='w-full mx-[1rem] my-2 flex flex-wrap gap-2 overflow-x-auto'>
                        {results && results.map(({ id, title, filename, extension }) => {

                            return (
                                <div className="flex flex-col gap-1 border rounded-md w-[6rem] max-h-[12rem] bg-white" data-fileid={id} onClick={selectFile}>
                                    <IconButton variant='text' className='mx-auto' color="blue-gray" >
                                        <DocumentIcon className='w-10 mt-1' extension={extension} innerClassName="pl-2" />
                                    </IconButton>
                                    <div className='line-clamp-2'>
                                        <Typography variant="small" label="Tytuł" className="text-center mx-auto text-xs text-blue-500" >{title}</Typography>
                                    </div>
                                    {/* 
                                    <div className='line-clamp-2'>
                                        <Typography variant="small" label="Tytuł" className="text-center mx-auto text-xs " >{filename}</Typography>
                                    </div>
                                    */}
                                </div>
                            )
                        })}
                    </div>
                }

                <div className='mx-auto w-full rounded-lg bg-gray-50 h-8 my-2'>
                    {labParams.length>0 && <Tabs value={selectedParam}>
                        <TabsHeader className='text-xs h-8 '>
                            {
                            labParams.map(param => {
                                let friendlyname=param;
                                switch (param) {
                                    case "Ocena_ph" : friendlyname = "Ocena PH"; break;
                                    case "Ocena_p2o5" : friendlyname = "Ocena Fosfor"; break;
                                    case "Ocena_k2o" : friendlyname = "Ocena Potas"; break;
                                    case "Ocena_mg" : friendlyname = "Ocena Magnez"; break;
                                    case "ph_gleby" : friendlyname = "Wartość PH"; break;
                                    case "P2O5" : friendlyname = "Fosfor"; break;
                                    case "K2O" : friendlyname = "Potas"; break;
                                    case "MG" : friendlyname = "Magnez"; break;
                                }
                                
                                return (
                                <Tab key={param} value={param} data-param={param} className='text-xs' onClick={handleParamChange}>
                                    {friendlyname}
                                </Tab>
                                )
                            })}
                        </TabsHeader>
                    </Tabs>
                            }
                </div>

                <MapboxPanel features={featureCollection} selected={selectedParam} min={min} max={max} showLegend={true}/>
                
            </div>
        )
    }











    return (

        <div className='fixed w-full h-full top-0 left-0 items-center justify-center m-auto z-[20] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-auto max-w-[90vw] mx-auto rounded-lg shadow-lg max-h-[94vh] " shadow={true}>

                <div className="flex items-center justify-between gap-2 w-auto mt-2">
                    <Typography color="gray" className="mt-0 pl-12 font-normal mx-auto">
                        Wyniki badań
                    </Typography>
                    <IconButton variant='text' className='-top-1 right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>
                <div className="w-auto flex flex-row items-center gap-2 m-2">
                    <Panel />
                    <Panel />
                </div>
            </Card>
            {showFileCard && <FileUploadCard close={handleFileCardClose} fieldid={fieldid} />}
        </div>

    );


}

