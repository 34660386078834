import React, { useState } from 'react';

import { ViewFieldCard, ViewFieldCardSmall, HorizontalFieldCard, VerticalFieldCard } from './ViewFieldCard';
import FieldCard from './FieldCard';

import {
  Card,
  Input,
  IconButton,
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,

} from "@material-tailwind/react";
import {
  MapIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  UserPlusIcon,
  FolderOpenIcon,
  FolderMinusIcon,
} from "@heroicons/react/24/outline";


import { getFields, getFieldsInfo, listStorageItems } from './AdminTools';

export default function FieldsList(props) {
  const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields") || []));
  const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);
  const [fieldid, setFieldId] = useState(0);
  const [owner, setOwner] = useState(props.clientid);
  const [search, setSearch] = useState('');
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [failed, setFailed] = useState(false);
  const [message, setMessage] = useState('');
  const [showFieldCard, setShowFieldCard] = useState(false);

  const [hidden, setHidden] = useState('');

  React.useEffect(() => {
    listStorageItems();
    //getFields(session,setFields, props.clientid); //pobierz listę pól
    

    getFields(session,setFields, props.clientid); //pobierz listę informacji o polach
    if (clients.length == 0) {
      getClients();
    }
  }, []);


  /*  
  function getFields() {
    var bodydata;
    console.log('get fields of owner ' + props.clientid);
    bodydata = JSON.stringify({ action: 'list', session: session, owner: props.clientid });
    console.log(bodydata);
    fetch('https://agro-online.pl/cgi-bin/fields.py', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: bodydata,
      mode: 'cors'

    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setFields(data.fields);
        if (data.fields) {
          localStorage.removeItem("fields");
          //localStorage.setItem("fields", JSON.stringify(data.fields));
        }
      }) // może jednak ZDECYDOWANIE NIE DAWAĆ do local storage
  }
  */


  function getClients() {
    var bodydata = JSON.stringify({ action: 'clients', session: session });
    console.log(bodydata);
    fetch('https://agro-online.pl/cgi-bin/user.py', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: bodydata,
      mode: 'cors'

    })
      .then(res => res.json())
      .then(data => { console.log(data); setClients(data.clients); localStorage.setItem("clients", JSON.stringify(data.clients)); })
  }



  const handleShowFieldCard = (e) => {
    const id = e.currentTarget.getAttribute("data-fieldid");
    setFieldId(id);
    const owner = e.currentTarget.getAttribute("data-ownerid");
    setOwner(owner);
    setHidden('hidden');
    setShowFieldCard(showFieldCard === true ? false : true);
  };



  function FieldItem(props) {
    return (
      <div className="flex items-center justify-between gap-1 w-auto my-1">
        <div>
          <Button size="sm" variant='text' className='w-[14rem] text-left text-slate-800 normal-case'>{props.name}</Button>
          <Tooltip content="Edytuj dane pola">
            <IconButton size="sm" variant='text' className='' color="blue-gray" data-fieldid={props.id} onClick={handleShowFieldCard}>
              <PencilSquareIcon className='w-6 h-6' />
            </IconButton>
          </Tooltip>
          <Tooltip content="Pokaż pole na mapie">
            <IconButton size="sm" variant='text' className='' color="blue-gray" >
              <MapIcon className='w-6 h-6' />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }


  //callback from field card
  function cardCallback(refresh) {
    setShowFieldCard();
    setHidden('');
    if (refresh == true) getFields();
  }



  function RenderFieldsList() {
    var select = [];
    var lsearch = search.toLowerCase();
    if (!fields) return;
    if (search.length == 0 || search == '') { select = fields; }
    else if (search.length == 1) { select = fields.filter(c => c.name.toLowerCase().startsWith(lsearch)); }
    else { select = fields.filter(c => c.name.toLowerCase().includes(lsearch)); }
    console.log('fields');
    console.log(fields);

    return (
      select.map(({ id, owner, image, name, area, crops }) => {
        let ownername = "klient usunięty";
        try {
          let clientarray = clients.filter(c => c.id == owner);
          if (clientarray.length == 1)
            ownername = clientarray[0].name;
        }
        catch { };
        return (
          <ListItem key={id} className='p-2 m-0' data-fieldid={id} data-ownerid={owner} onClick={handleShowFieldCard}>
            {/*<HorizontalFieldCard img={image} name={name} area={area} crops={crops} ownername={ownername} /> */}
            <HorizontalFieldCard id={id} img={image} name={name} area={area} crops={crops} owner={owner} ownername={ownername} />
          </ListItem>

        )
      }
      )
    )
  }




  return (
    <>
      <Card className={`absolute h-[calc(100vh-2rem)] w-full max-w-[20rem] top-[1rem] p-4 shadow-xl lg:left-[17rem] left-[6rem] z-10 overflow-y-auto overflow-x-hidden ${hidden}`} >
        <div className="mb-2 p-2 flex mx-auto">
          <Input label="Szukaj" onChange={e => setSearch(e.target.value)} />
          <MagnifyingGlassIcon className='relative right-7 top-2 h-6 w-6 text-blue-500' />

          <Tooltip content="Dodaj pole">
            <IconButton size="md" variant="gradient" className='px-4' color="green" data-fieldid={0} onClick={handleShowFieldCard} >
              <MapIcon className='w-5 h-5' color="white" strokeWidth={2} />
            </IconButton>
          </Tooltip>
        </div>
        <List className='p-0 mx-0'>
          <RenderFieldsList />
        </List>
      </Card>
      {showFieldCard === true ? <FieldCard close={cardCallback} fieldid={fieldid} clientid={owner} fields={fields} /> : ''}
    </>
  );
}

