import {
  Badge,
  Chip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stepper,
  Step,
  Progress,
  Typography,
  Tooltip,
} from "@material-tailwind/react";



export function OrderItemHorizontal(props) {
  return (
    <Card className="w-full max-w-[48rem] h-[100px] flex-row border-cyan-200 hover:border-cyan-600 border">
      <CardHeader shadow={false} floated={false} className="m-0 w-1/3 shrink-0 items-center rounded-r-none">
        <img src={props.img} className="h-full w-full object-cover " />
        <div className="flex items-center relative -top-4" >
        <Typography variant="h5" color="white" className="text-xs mx-auto">
          {props.area} Ha
        </Typography>
        </div>
      </CardHeader>
      <CardBody className="flex flex-col items-center justify-between w-full p-1">
        <Typography variant="h6" color="gray" className="my-auto text-xs">
          {props.fieldname}
        </Typography>
        <Typography variant="h6" color="blue-gray" className="my-auto text-xs">
          {props.crops}
        </Typography>
        <div className="flex flex-col gap-0 w-full mb-1 ">
        <Progress value={props.progress} size="md" className="w-40 mx-auto"/>
        <Typography variant="small" color="gray" className="mx-auto text-xs">
          {props.status}
        </Typography>
        
        </div>    
      </CardBody>
    </Card>

  )
}  