import React, { useState } from "react";
import  {Navigate, useNavigate } from 'react-router-dom';

import { ClientNavbar } from './ClientNavBar';
import { Button } from '@material-tailwind/react';
import { Typography } from '@material-tailwind/react'
import { Sidebar } from './Sidebar';
import MapBox from './MapBox';
import AlertCard from "./Alert";

function Client() {
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [rank, setRank] = useState(localStorage.getItem("rank") || 0);
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
  const navigate = useNavigate();

  
  React.useEffect(() => {
   
    if ((!session) || (rank!=1)) navigate('/')  
    
  }, []);


  if (authenticated && rank==1) {
  return (
      <div className='h-screen w-screen fixed'>
      
        <div >
        <MapBox />
        </div>

        <div className='absolute top-2 left-0 w-screen z-30 flex content-evenly justify-items-center h-auto'>
          <div className='bg-white rounded-lg mx-auto float-right flex max-w-screen-xl'>
          <ClientNavbar className="max-w-screen-md" />
          </div>
        </div>
        <AlertCard />
      
      </div>
    );}
    else
    {
      return <Navigate to={"/"} replace />;
    }
  }
  
export default Client;
  