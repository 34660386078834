import { getVehicles } from "./AdminTools";
import React, { useState, useRef } from 'react';

export function VehiclesUpdater() {
    const [vehicles, setVehicles] = useState([]);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
  
    React.useEffect(() => {
      
      setTimeout(() => {
        getVehicles(session, updateVehicles);
      }, 1000);
      setTimeout(() => {
        getVehicles(session, updateVehicles);
      }, 3000);
      
      initVehicles();
    }, []);

    function updateVehicles(vehicles) {
        setVehicles(vehicles);
        dispatchEvent(new Event("vehiclesChanged"));
      }
    

    function initVehicles()
    {
      const interval = setInterval(() => {
        let lock = localStorage.getItem("lockvehicles") || false;
        if (lock != "true") getVehicles(session, updateVehicles);
      }, 30000);
      
      return () => clearInterval(interval);
    }


}

  