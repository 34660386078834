import * as React from 'react';
import { useState, useRef, useCallback } from 'react';
import Map, { UseMap, NavigationControl, Source, Layer, MapProvider, Popup, Marker } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import DrawControl from './draw-control.tsx';
import ControlPanel from './control-panel.tsx';
import { Button, ButtonGroup, Tooltip, Typography } from '@material-tailwind/react';
import {
  Card,
} from "@material-tailwind/react";
import { Polygon } from 'leaflet';
import ShapeUnziper from './ShapeUnziper.jsx';
import * as turf from '@turf/turf';

import { CheckIcon, PencilIcon, XMarkIcon, XCircleIcon, ArrowUpCircleIcon, ChevronDoubleUpIcon, PrinterIcon,InboxArrowDownIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import IconButton from '@material-tailwind/react';
import { edit } from 'fontawesome';

import { PopUpCard } from './PopUpCard.jsx';
import { geoportalGetFeatureInfo, geoportalGetFeatureInfoOld, geoportalGetParcelPolygon } from './geoportal.js';

const dataLayer = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#e0e060',
    'fill-opacity': 0.8
  }
};




const myfield = {
  id: "myfield",
  type: "Feature",
  properties: { type: "Feature" },
  geometry: {
    type: "Polygon",
    coordinates: [[[15.582837, 53.658105],
    [15.663862, 53.658105],
    [15.667295, 53.597432],
    [15.588331, 53.601914],
    [15.582837, 53.658105]
    ]
    ]
  }
};




/*
var emptyGeoJson = {
  type: "FeatureCollection",
  features: []
}
*/

let emptyGeoJson = {
  type: "FeatureCollection",
  features: []
}


const fillStyle = {
  id: 'polygonfill',
  type: 'fill',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'fill-color': '#ffff80', // yellow color fill
    'fill-opacity': 0.5
  }
};

const lineStyle = {
  id: 'polygonlines',
  type: 'line',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'line-color': {
      property: "value",
      stops: [
        [0, '#0000ff'],
        [1, '#00ff00'],
        [2, '#80ff00'],
        [3, '#ffff00'],
        [4, '#ff8000'],
        [5, '#ff0000'],
        [10, '#ff00ff']
      ]
    },
    'line-width': 2
  }
};

const timeStyle = {
  id: 'focuspolygonfill',
  type: 'fill',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'fill-color': {
      property: "value",
      stops: [
        [0, '#4040ff'],
        [1, '#00ff00'],
        [2, '#80ff00'],
        [3, '#ffff00'],
        [4, '#ff8000'],
        [5, '#ff0000'],
        [10, '#ff00ff']
      ]
    },
    'fill-opacity': 0.6
  }
};




const polygonNameStyle = {
  "id": "SectionTextLayer",
  "type": "symbol",
  "source": "myfield",
  "paint": {
    "text-color": "black", //Color of your choice
  },
  "layout": {
    "text-field": ['get', 'polygonName'],
    "text-rotation-alignment": "auto",
    "text-allow-overlap": true,
    "text-anchor": "top"
  }
}




const geoFillStyle = {
  id: 'geoPolygonFill',
  type: 'fill',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'fill-color': '#00ffff', // lime fill
    'fill-opacity': 0.5
  }
};

const geoFineStyle = {
  id: 'geoPolygonLines',
  type: 'line',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'line-color': '#00ffff', //lime
    'line-width': 2
  }
};

const geoPolygonNameStyle = {
  "id": "geoSectionTextLayer",
  "type": "symbol",
  "source": "myfield",
  "paint": {
    "text-color": "white", //Color of your choice
  },
  "layout": {
    "text-field": ['get', 'polygonName'],
    "text-rotation-alignment": "auto",
    "text-allow-overlap": true,
    "text-anchor": "top"
  }
}


const initialView = {
  longitude: 15.62129,
  latitude: 53.63918,
  zoom: 11
};


const MESSAGES = [
  "Narysuj obrys pola, zaznaczając koleje wierzchołki wielokąta",
  "Aby zakończyć rysowanie wielokąta kliknij dwukrotnie na ostatnim wierzchołku",
  "Możesz zapisać obrys pola, lub dalej je modyfikować",
  "Edytuj obrys pola, kliknij przycisk zapisz gdy zakończysz edycję",
  "Zaznacz obszar wyłączony z upraw (musi całkowicie mieścić się w jednym obszarze), kliknij przycisk zapisz gdy zakończysz edycję",
]


export default function Mapbox(props) {

  const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
  const [vehicles, setVehicles] = useState(JSON.parse(localStorage.getItem("vehicles")) || []);
  const [featureCollection, setFeatureCollection] = useState(JSON.parse(localStorage.getItem("featureCollection")) || emptyGeoJson);
  const [geoFeature, setGeoFeature] = useState(emptyGeoJson);
  const [show, setShow] = useState(true);
  const [viewState, setViewState] = useState(JSON.parse(localStorage.getItem("mapView")) || initialView);
  const [boundingBox, setBoundingBox] = useState([]);
  //const [drawingfeature, setDrawingFeature] = useState(JSON.parse(localStorage.getItem("drawingFeature")) || emptyGeoJson);
  const [drawingfeature, setDrawingFeature] = useState(emptyGeoJson);

  const [lastDrawMode, setLastDrawMode] = useState('init');
  const [drawMessage, setDrawMessage] = useState(MESSAGES[0])
  const [editMode, setEditMode] = useState(false);
  const [holeMode, setHoleMode] = useState(false);


  const [showPopup, setShowPopup] = useState(false);
  const [legend, setLegend] = useState(props.legend ||"");
  const [filterButtons, setFilterButtons] = useState(props.filter || false);
  const [popupLeft, setPopupLeft] = useState();
  const [popupTop, setPopupTop] = useState();
  const [popupLat, setPopupLat] = useState();
  const [popupLng, setPopupLng] = useState();

  const [clickPosition, setClickPosition] = useState();
  const [popupItems, setPopupItems] = useState([]);
  const [popupPosition, setPopupPosition] = useState('left-[300px] top-[300px]');
  const [waitingForData, setWaitingForData] = useState(false);
  const [geoportalTimedOut, setGeoportalTimedOut] = useState(false);
  const mapRef = useRef({});

  let drawoptions = { displayControlsDefault: false, controls: { polygon: true, trash: true } };
  const [drawControl, setDrawControl] = useState();


  React.useEffect(() => {
    addEventListener('featureCollectionChanged', (e) => {
      e.preventDefault();
      setFeatureCollection(JSON.parse(localStorage.getItem("featureCollection")));
    })

    addEventListener('vehiclesChanged', (e) => {
      e.preventDefault();
      setVehicles(JSON.parse(localStorage.getItem("vehicles")));
    })


    addEventListener('boundingBoxChanged', (e) => {
      e.preventDefault();
      //console.log('what is e?');
      //console.log(e);
      let bbox = JSON.parse(localStorage.getItem("boundingBox"));
      setBounds(bbox);
      setBoundingBox(bbox);
    })


    addEventListener('enterEditMode', (e) => {
      e.preventDefault();

      let feature;
      try {
        feature = JSON.parse(localStorage.getItem("drawingFeature"));
      }
      catch { console.log('parse exception'); }
      console.log('starting with feature');
      console.log(feature);
      setDrawingFeature(feature);
      setEditMode(true);

      enter_drawing_mode(feature);
    })


  }, []);

  React.useEffect(() => {
  setLegend(props.legend);
  console.log('legend changed');
  },[props.legend]);

  React.useEffect(() => {
    setFilterButtons(props.filter);
    console.log('filter changed');
    },[props.filter]);
  
  

  function closePopup() {
    setShowPopup(false);
    hideGeoPolygon();
  }



  function applyFinishDrawing(features) {
    localStorage.setItem('drawingFeature', JSON.stringify(features));
    dispatchEvent(new Event('featureDrawingFinished'));
  }

  function updateFields() {
    let fields = JSON.parse(localStorage.getItem("fields"));
    setFields(fields);
    return fields;
  }


  const onUpdate = useCallback(e => {
    console.log('onUpdate');
    setFeature(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      console.log('e.features');
      console.log(e.features);

      console.log('Update newfeatures');
      console.log(newFeatures);
      return newFeatures;
    });

  }, []);


  const onDelete = useCallback(e => {
    console.log('onDelete');
    console.log(e.Features);
    setFeature(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });

  }, []);








  function resizeBoundingBox(bounds) {
    var point1 = turf.point([bounds[0], bounds[1]]);
    var point2 = turf.point([bounds[2], bounds[3]]);
    var bearing = turf.bearing(point1, point2);
    var distance = turf.distance(point1, point2);
    var p1 = turf.destination(point1, -0.5 * distance, bearing);
    var p2 = turf.destination(point2, 0.5 * distance, bearing);
    var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
    return box;
  }




  function showGeoJson(feature) {
    console.log('Show geojson with ' + feature.type);
    if (feature.type == "FeatureCollection") {
      setFeatureCollection(feature);
    }
    if (feature.type == "Feature") {
      let geo = {
        "type": "FeatureCollection",
        features: [feature]
      }
      setFeatureCollection(geo);
    }


    var bounds = turf.bbox(feature);
    bounds = resizeBoundingBox(bounds);
    //setBounds(bounds);
  }


  function setBounds(bounds) {
    if (mapRef.current)
      mapRef.current.fitBounds(bounds, { duration: 0 });
  }



  const mapUpdate = useCallback(e => {
    console.log('map Update');
    console.log(e);
  }, []);




  const onClick = useCallback(e => {
    console.log(e);
    //setTrace(true);
  }, []);


  function mapRightClick(e) {
    if (e.originalEvent.button === 2) {
      console.log("right click");
      /*
      if (editMode==true) {
        let mode = drawControl.getMode();
        console.log("mode");
        console.log(drawControl);
        let selected=drawControl.getSelectedPoints();
        console.log("selected points");
        console.log(selected);
        if (selected.features.length==1)
          {
            console.log("deleting");
            drawControl.trash();
          }
        }
        */
    }
  }



  const { fromLatLon, toLatLon } = require('./cartography.js');
  const proj4 = require("proj4").default


  function mapClick(e) {
    console.log('click');
    console.log(e);
    if (editMode == true) {
      if ((drawControl) && (mapRef.current.hasControl(drawControl))) {
        let mode = drawControl.getMode();
        console.log(mode)
        let poly = drawControl.getAll();
        setDrawingFeature(poly);
        let area = 0;
        try {
          area = turf.area(poly);
        } catch { console.log('no area!') };


        if (mode == "draw_polygon") {
          if (area > 0) setDrawMessage(MESSAGES[1]);
          setLastDrawMode(mode);
        }

        if (mode == "simple_select") {
          if (lastDrawMode == "draw_polygon") setDrawMessage(MESSAGES[2]);
        }

        if (mode != lastDrawMode) {
          if (mode == "draw_polygon") {
            console.log('just draw');
            console.log(poly);

          }
          if (mode == "simple_select") {
            if (holeMode) {
              console.log('Checking holes');
              let features = drawControl.getAll();
              console.log(features);
              findHoles(features);
              setHoleMode(false);

            }

          }
          if (mode == "direct_select") {


          }

          setLastDrawMode(mode);
        }
      }
    }
    else {
      //just a click
      //console.log(e);
      if (boundingBox) {
        setWaitingForData(true);
        setGeoportalTimedOut(false)
        geoportalGetFeatureInfo(boundingBox, e.target._containerWidth, e.target._containerHeight, e.point, gotGeoportalFeature);
        setTimeout(geoPortalTime, 6000);
      }

      console.log('geoFeature');
      console.log(geoFeature);

      //setShowPopup(false);
      //setPopupLeft((e.point.x + 6) + "px");
      //setPopupTop((e.point.y + 6) + "px");
      //setPopupPosition(" left-[" + (e.point.x + 6) + "px] top-[" + (e.point.y + 6) + "px] ");
      //setPopupLat(e.lngLat.lat);
      //setPopupLng(e.lngLat.lng);
      setClickPosition(e.lngLat)
      preparePopUpItems(e.lngLat);
      setShowPopup(true);
    }
  }

  function geoPortalTime() {
    //console.log('waiting ? ' + waitingForData);
    if (waitingForData==true) { console.log('bad'); setGeoportalTimedOut(true); }
      else { setGeoportalTimedOut(false); }
      //console.log('timeout')
  }




  function findField(position) {
    let hitfeat = null;

    if (fields.length == 0) {
      updateFields();
      return hitfeat;
    }
    console.log('find fields');
    console.log(fields);
    fields.forEach(field => {
      if (field.shape) {
      let shape = JSON.parse(field.shape);
      shape.features.forEach(feat => {
        if (turf.booleanPointInPolygon([position.lng, position.lat], feat)) {
          hitfeat = feat;
        }
      })
      }
    })
    return hitfeat;
  }

  let popitems = [];

  function preparePopUpItems(position) {
    let field;
    popitems = [];
    field = findField(position);
    popitems.push({ item: "Lat:", value: position.lat.toFixed(7) });
    popitems.push({ item: "Lng:", value: position.lng.toFixed(7) });
    popitems.push({ item: "", value: "" });
    if (field) {
      for (const [item, value] of Object.entries(field.properties)) {
        if ((item != "path") && (item != "__dart_typ")) popitems.push({ item: item, value: value });
      }
      popitems.push({ item: "", value: "" });
    }
    setPopupItems(popitems);
    
  }


  function gotGeoportalFeature(json) {

    setWaitingForData(false);
    
    popitems.push({ item: "Identyfikator działki", value: json['Identyfikator działki'] });
    popitems.push({ item: "Numer działki", value: json['Numer działki'] });
    popitems.push({ item: "Gmina", value: json['Gmina'] });
    popitems.push({ item: "Obręb", value: json['Obręb'] });
    popitems.push({ item: "Oznaczenie konturu", value: json['Oznaczenie konturu'] });
    
    setPopupItems(popitems);
    
    let ewid = json['Identyfikator działki'];
    if (ewid) {
      console.log('ewid');
      console.log(ewid);
      console.log(json);
      geoportalGetParcelPolygon(ewid, showGeoPolygon);
    }
  }

  function showGeoPolygon(polygon) {
    let collection = {
      "type": "FeatureCollection",
      features: [polygon]
    }
    console.log('show geo collection');
    console.log(collection);
    //setFeatureCollection(collection);
    setGeoFeature(collection);
  }

  function hideGeoPolygon() {
    let collection = {
      "type": "FeatureCollection",
      features: []
    }
    setGeoFeature(emptyGeoJson);
  }


  function mapMouseMove(e) {
    //console.log(e);
  }


  function findHoles(features) {
    let found = false;
    let lastidx = features.features.length;
    if (lastidx < 2) return; // one polygon - nothing to cut

    let lastfeat = features.features[lastidx - 1];
    console.log('last one')
    console.log(lastfeat);
    for (let idx = 0; idx < lastidx - 1; idx++) {
      if (turf.booleanWithin(lastfeat, features.features[idx])) {
        console.log('found at ' + idx)
        console.log(idx);
        features.features[idx] = turf.difference(features.features[idx], lastfeat);
        features.features.pop(); //remove lastfeat
        found = true;
        break;
      }

    }

    console.log('finished searching ');
    if (found) {
      console.log('removing features from DC :')
      console.log(drawControl)
      drawControl.deleteAll();
      console.log('adding features')
      console.log(features)
      drawControl.add(features);
    }


  }





  function enter_hole_drawing() {
    if (editMode == true) {
      if ((drawControl) && (mapRef.current.hasControl(drawControl))) {

        let mode = drawControl.getMode();

        if (mode == "direct_select") {
          drawControl.changeMode("simple_select");
        }

        if ((mode == "simple_select") || (mode == "direct_select")) {
          {
            setHoleMode(true);
            drawControl.changeMode("draw_polygon");
          }
        }
      }
    }
  }



  function enter_drawing_mode(feat) {
    console.log("enter drawing mode function with :")
    console.log(feat);
    if ((drawControl == null) && (mapRef.current != null)) {
      let _draw = new MapboxDraw(drawoptions);
      console.log("adding draw control ")
      try {
        setDrawControl(_draw);
        mapRef.current.addControl(_draw, "bottom-right");

      } catch { console.log("exception while adding draw control") }

      setLastDrawMode('enter_drawing');


      let coords = 0;
      try {
        coords = feat.features[0].geometry.coordinates[0].length;
        feat.features[0].id = "edit";
      } catch { };
      console.log("add feat ")
      console.log(feat);
      try {
        _draw.add(feat);
      } catch { console.log('adding feature fired an exception'); }

      console.log("change mode ")
      if (coords == 0) { _draw.changeMode("draw_polygon"); }
      else { _draw.changeMode("direct_select", { "featureId": "edit" }); setDrawMessage(MESSAGES[3]); }
    }
    else {
      //draw.changeMode("direct_select");
    }
  }



  function finish_edit_mode() {
    console.log('checking existing draw');
    console.log(drawControl);
    if (drawControl) {
      console.log('has control?');

      if (mapRef.current.hasControl(drawControl)) {
        console.log('trying remove polygons');

        try {
          drawControl.removeAll();
          setDrawMessage(MESSAGES[0])
        } catch { 'draw.removeAll failed' };
        console.log('trying remove draw');
        mapRef.current.removeControl(drawControl);
      }
      setDrawControl(null);
    }
    setEditMode(false);
  }

  function cancel_drawing() {
    setDrawingFeature(null);
    applyFinishDrawing(null);
    finish_edit_mode();
  }


  function save_drawing() {
    if (!drawControl) return;
    let features = drawControl.getAll();
    setDrawingFeature(features);
    applyFinishDrawing(features);
    finish_edit_mode();
  }

  function mapIdle(e) {
    setBoundingBox(e.target.getBounds());
  }


  const mapMove = React.useCallback(({ viewState }) => {
    // update the view state
    setViewState(viewState);
    //localStorage.setItem('viewState', JSON.stringify(viewState));

  }, [])

  const onMapLoad = React.useCallback((e) => {
    setBoundingBox(e.target.getBounds());
  }, [])



  function mapBlob(e) {
    console.log('blob');
    console.log(e);
    const newImg = document.createElement("img");
    newImg.src = e;
    document.body.appendChild(newImg);
  }


  function getCanvas() {
    var img = mapRef.current.getCanvas().toDataURL('image/png');
    mapRef.current.getCanvas().toBlob(mapBlob, 'image/png');
    console.log(img);

  }

  function printCanvas2() {
    console.log('print');
    let img = mapRef.current.getCanvas().toDataURL('image/png');
    console.log('image');
    console.log(img);

    const myImage = new Image(img.width,img.height);
    myImage.src = img;
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write(myImage);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
 
  }


  function printCanvas() {
    var dataUrl = mapRef.current.getCanvas().toDataURL('image/png');
    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Print canvas</title></head>';
    windowContent += '<body>'
    windowContent += '<img src="' + dataUrl + '">';
    windowContent += '</body>';
    windowContent += '</html>';
    const printWindow = window.open('', '_blank');
    printWindow.document.write(windowContent);
    printWindow.document.close();
    printWindow.print();

  }


  function downloadCanvas() {
    var dataUrl = mapRef.current.getCanvas().toDataURL('image/png');
    let alink = document.createElement("a");
    alink.href = dataUrl;
    alink.download = "mapa.png";
    alink.click();
    window.URL.revokeObjectURL(dataUrl);

  }


  function logFeatures() {
    console.log('Assign test');
    localStorage.removeItem('featureCollection');
  }


  function DrawingButtons() {
    return (
      <div className="absolute bottom-[4rem] mx-auto w-screen flex flex-col items-center ">
        <div className='inline-flex bg-black/50 w-auto mb-[2rem] px-4 py-2 rounded-lg'>
          <Typography color="white" variant="small" >{drawMessage}</Typography>
        </div>

        <div className='flex-row mx-auto'>
          <Tooltip content="Rozpocznij rysowanie pola">
            <Button variant="filled" className='hidden p-2 bg-black/50 hover:bg-black/80 mr-12' onClick={() => enter_drawing_mode()} >
              <PencilIcon className='w-8 h-8' color="white" />
            </Button>
          </Tooltip>


          <Tooltip content="Zakończ i zapisz">
            <Button variant="filled" className='p-2 bg-black/50 hover:bg-black/80 mr-12' onClick={() => save_drawing()} >
              <CheckIcon className='w-8 h-8' color="green" />
            </Button>
          </Tooltip>

          <Tooltip content="Zaznacz obszar wyłączony z uprawy">
            <Button variant="filled" className='p-2 bg-black/50 hover:bg-black/80 mr-12' onClick={() => enter_hole_drawing()} >
              <XCircleIcon className='w-8 h-8' color="teal" />
            </Button>
          </Tooltip>

          <Tooltip content="Anuluj">
            <Button variant="filled" className='p-2 bg-black/50 hover:bg-black/80' onClick={() => cancel_drawing()} >
              <XMarkIcon className='w-8 h-8' color="red" />
            </Button>
          </Tooltip>

        </div>
      </div>
    )
  }

  const renderVehicleMarker = (vehicle, index) => {
    return (
      <Marker
        key={`vehicle-${index}`}
        longitude={vehicle.longitude}
        latitude={vehicle.latitude}
        rotation={vehicle.direction}
      >
      <ArrowUpCircleIcon className='w-7 h-7 bg-white rounded-full' color="teal"/>
      </Marker>
    );
  };


  const renderVehicleName = (vehicle, index) => {
    return (
      <Marker
        key={`name-${index}`}
        longitude={vehicle.longitude}
        latitude={vehicle.latitude}
        offset={[0,50]}
      >
      <div className='w-auto h-auto px-1 bg-black/50 rounded-md text-white items-center justify-center flex flex-col'>
        <p className="font-bold">{vehicle.name}</p>
        <p>Prędkość {vehicle.speed} kmh</p>
        <p>{vehicle.time}</p>
      </div>
      </Marker>
    );
  };

  function OldDrawingButtons() {
    return (
      <div className="absolute bottom-[4rem] mx-auto w-screen flex items-center ">
        <div className='inline-flex mx-auto gap-1'>
          <ButtonGroup className='bg-gray-900/' color="gray">
            <Tooltip content="Rozpocznij rysowanie pola">
              <Button variant="filled" className='p-2 bg-gray-200 hover:shadow-none hover:bg-blue-200' onClick={() => enter_drawing_mode()} >
                <PencilIcon className='w-5 h-5' color="black" />
              </Button>
            </Tooltip>
            <Tooltip content="Zakończ i zapisz">
              <Button variant="filled" className='p-2 bg-gray-200 hover:shadow-none hover:bg-blue-200' onClick={() => save_drawing()} >
                <CheckIcon className='w-5 h-5' color="green" />
              </Button>
            </Tooltip>
            <Tooltip content="Anuluj">
              <Button variant="filled" className='p-2 bg-gray-200 hover:shadow-none hover:bg-blue-200' onClick={() => remove_drawing()} >
                <XMarkIcon className='w-5 h-5' color="red" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
    )
  }

  return (
    <Map
      id="mainMap"
      ref={mapRef}
      mapLib={import('mapbox-gl')}
      mapboxAccessToken="pk.eyJ1Ijoia2lsbGVyZWsiLCJhIjoiY2xrdXlkdDVrMDNmcjNkcXprcXNhaXAzaiJ9.PXuJL9WHJpLU4cJBCu8nhA"
      {...viewState}
      onIdle={mapIdle}
      onMove={mapMove}
      onClick={mapClick}
      onLoad={onMapLoad}
      onRightClick={mapRightClick}
      onMouseDown={mapRightClick}
      onMouseMove={mapMouseMove}
      movingMethod="jumpTo"
      interactiveLayerIds={['myfield', 'polygonfill', 'polygonlines']}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      style={{ position: 'fixed', width: '100%', height: '100%', top: 0 }}
      attributionControl={false}
      preserveDrawingBuffer={true}
    >

      <NavigationControl position="bottom-right" />



      <Source id="myfield" type="geojson" data={featureCollection}>
        <Layer {...timeStyle} />
        <Layer {...lineStyle} />
        <Layer {...polygonNameStyle} />
      </Source>

      <Source id="geofield" type="geojson" data={geoFeature}>
        <Layer {...geoFillStyle} />
        <Layer {...geoFineStyle} />
        <Layer {...geoPolygonNameStyle} />
      </Source>

      {vehicles && vehicles.map(renderVehicleMarker)}
      {vehicles && vehicles.map(renderVehicleName)}

      <ControlPanel polygons={drawingfeature} view={viewState} />

      {showPopup &&
        <PopUpCard content={popupItems} waiting={waitingForData} timeout={geoportalTimedOut} close={closePopup} />
      }

            <div className='absolute bottom-2 right-16'> 
            <Tooltip content="Drukuj mapę">
              <Button variant="filled" className='p-2 rounded-r-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={printCanvas} >
                <PrinterIcon className='w-4 h-4' color="black" />
              </Button>
            </Tooltip>
            <Tooltip content="Zapisz mapę">
              <Button variant="filled" className='p-2 rounded-l-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={downloadCanvas} >
                <InboxArrowDownIcon className='w-4 h-4' color="black" />
              </Button>
            </Tooltip>
            </div>






      {(editMode == true) && <DrawingButtons />}
    </Map>

  );
}

