import React, { useState } from 'react';

import {
    Accordion,
    AccordionHeader,
    AccordionBody,
    Card,
    CardHeader,
    CardBody,
    IconButton,
    Button,
    Tooltip,
    ListItem,
    ListItemPrefix,
    Typography,
    Progress,

} from "@material-tailwind/react";
import {
    PencilSquareIcon,
    UserIcon,
    FolderPlusIcon,
    XMarkIcon,
    DocumentPlusIcon,
    DocumentArrowDownIcon
} from "@heroicons/react/24/outline";


import OfferCard from './OfferCard';
import OrderAdminCard from './OrderAdminCard';
import { getClients, getOffers, getOrders, getDocuments, downloadDocument } from './AdminTools';
import Datepicker from "react-tailwindcss-datepicker";
import { json } from 'react-router-dom';
import FileUploadCard from './FileUploadCard';


const CATEGORIES = [
    { category: "invoice", name: "Faktura VAT" },
    { category: "results", name: "Wyniki badań" },
    { category: "fertilization", name: "Mapa nawożenia" },
    { category: "company", name: "Dokumenty firmowe" },
    { category: "internal", name: "Dokumenty wewnętrzne" },
    { category: "personal", name: "Dokumenty pracownika" },
    { category: "holiday", name: "Karta urlopowa" },
]



export default function DocumentsList(props) {
    const [offerid, setOfferId] = useState(0);
    const [fileid, setFileId] = useState(0);
    const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);
    const [orders, setOrders] = useState(JSON.parse(localStorage.getItem("orders")) || []);
    const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);
    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [documents, setDocuments] = useState(JSON.parse(localStorage.getItem("documents")) || []);

    const [invoices, setInvoices] = useState([]);
    const [personals, setPersonals] = useState([]);
    const [results, setResults] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [ferts, setFerts] = useState([]);
    const [companys, setCompanys] = useState([]);
    const [internals, setInternals] = useState([]);

    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState('');
    const [showDocumentCard, setShowDocumentCard] = useState(false);
    const [open, setOpen] = React.useState(0);



    


    React.useEffect(() => {
        if (offers.length == 0) getOffers(session, setOffers);
        if (orders.length == 0) getOrders(session, setOrders);
        if (clients.length == 0) getClients(session, setClients);
        
        if (documents.length == 0) { getDocuments(session, updateDocuments); }
        else {splitDocuments(documents); }
    }, []);



    const handleShowDocumentCard = (e) => {
        const id = e.currentTarget.getAttribute("data-fileid");
        setFileId(id);
        setShowDocumentCard(showDocumentCard === true ? false : true);
    };


    function cancelClose() {
        props.Close();
    }


    function handleHideCard(refresh) {
        setShowDocumentCard();
        if (refresh == true) getDocuments(session, updateDocuments);
    }



    function splitDocuments(docs) {
        setInvoices(docs.filter(d => d.category == "invoice"));
        setPersonals(docs.filter(d => d.category == "personal"));
        setResults(docs.filter(d => d.category == "results"));
        setHolidays(docs.filter(d => d.category == "holiday"));
        setFerts(docs.filter(d => d.category == "fertilization"));
        setCompanys(docs.filter(d => d.category == "comapny"));
        setInternals(docs.filter(d => d.category == "internal"));

    }

    function updateDocuments(docs) {
        console.log('updated docs len' + docs.length);
        setDocuments(docs);
        splitDocuments(docs);
    }


    function handleDownload(e) {
        const id = e.currentTarget.getAttribute("data-fileid");
        console.log("download file " + id);
        downloadDocument(session, id);
    }



    function FileTable(props) {
        return (
            <table className="w-full table-auto text-left">
                <thead>

                    <tr>
                        <th key="owner" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                            <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs">Właściciel</Typography>
                            </div>
                        </th>

                        <th key="title" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                            <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs">Tytuł</Typography>
                            </div>
                        </th>

                        <th key="name" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                            <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs hidden md:block">Nazwa pliku</Typography>
                            </div>
                        </th>

                        <th key="size" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                            <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs hidden md:block">Rozmiar</Typography>
                            </div>
                        </th>

                        <th key="type" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                            <div className='flex flex-col items-left'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 text-center md:text-md text-xs hidden md:block">Typ</Typography>
                            </div>
                        </th>

                        <th key="info" className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                            <div className='flex flex-col items-center'>
                                <Typography variant="h6" color="blue-gray" className="font-normal opacity-70 md:text-md text-xs hidden lg:block">Uwagi</Typography>
                            </div>
                        </th>

                        <th key="edit" className="border-y w-32 border-blue-gray-100 bg-blue-gray-50/50 p-2">
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {props.documents && props.documents.map(
                        (
                            {
                                id,
                                owner,
                                field,
                                filename,
                                title,
                                category,
                                properties,
                                date
                            },
                            index,
                        ) => {

                            const classes = "p-2";
                            let _client;
                            let _field;
                            let _catname;
                            try {
                                _client = clients.filter(c => c.id == owner)[0];
                                _field = fields.filter(c => c.id == field)[0];
                                _catname = CATEGORIES.filter(c => c.category == category)[0].name
                            }
                            catch { };
                            let _owner = null;
                            if (_field) _owner = _field.name;
                            if (_client) _owner = _client.name;
                            if (_owner == null) _owner = "AgroTechnology";
                            let _props = JSON.parse(properties);

                            let dateString = "";


                            return (
                                <tr key={index} className='even:bg-blue-gray-50/50'>
                                    <td className={classes}>
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {_owner}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="blue-gray" className="font-normal hidden md:block">
                                            {title}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="blue-gray" className="font-normal hidden md:block">
                                            {filename}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="blue-gray" className="font-normal">
                                            {Math.round(_props.size / 1024)}kB
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography variant="small" color="blue-gray" className="font-normal text-center">
                                            {_props.type}
                                        </Typography>
                                    </td>

                                    <td className={classes}>
                                        <Typography variant="small" color="blue-gray" className="font-normal text-xs mx-auto text-center hidden md:block">
                                            {_props.description}
                                        </Typography>
                                    </td>


                                    <td className="p2 items-right flex">
                                        <Tooltip content="Edytuj dane pliku" >
                                            <IconButton variant="gradient" size="sm" className="ml-auto mr-0 mt-1.5 " data-fileid={id} onClick={handleShowDocumentCard}>
                                                <PencilSquareIcon className="h-5 w-5" />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip content="Pobierz plik" >
                                            <IconButton variant="gradient" size="sm" className="ml-1 mr-0 mt-1.5 " color="purple" data-fileid={id} onClick={handleDownload}>
                                                <DocumentArrowDownIcon className="h-5 w-5" />
                                            </IconButton>
                                        </Tooltip>

                                    </td>
                                </tr>
                            );
                        },
                    )}
                </tbody>
            </table>

        )
    }

    
    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    return (
        <>
            <Card className={`absolute my-auto top-[1rem] max-h-[calc(100vh-2rem)] w-auto lg:max-w-[calc(100vw-18rem)] max-w-[calc(100vw-7rem)] shadow-xl lg:left-[17rem] left-[6rem] z-1 overflow-y-auto ${showDocumentCard == true ? 'opacity-75' : 'opacity-100'}`}>

                <CardHeader floated={false} shadow={false} className="w-full rounded-none mb-0 flex justify-between mx-0 px-2">

                    <Button className="flex items-center gap-2 mt-2 ml-2" size="sm" color="green" data-fileid={0} onClick={handleShowDocumentCard}>
                        <DocumentPlusIcon strokeWidth={2} className="h-4 w-4" /> Nowy dokument
                    </Button>

                    <IconButton variant='text' className='z-20 ' size="sm" color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-5 h-5' />
                    </IconButton>

                </CardHeader>
                <CardBody className="overflow-auto px-4">

                    {/** Good place for accordion  */}

                    <Accordion open={open === 1} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(1)} className={`border-b-0 transition-colors text-md p-3 ${open === 1 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Faktury 
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={invoices} />        
                        </AccordionBody>
                    </Accordion>

                    <Accordion open={open === 2} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(2)} className={`border-b-0 transition-colors text-md p-3 ${open === 2 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Wyniki badań 
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={results} />        
                        </AccordionBody>
                    </Accordion>

                    <Accordion open={open === 3} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(3)} className={`border-b-0 transition-colors text-md p-3 ${open === 3 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Pliki zaleceń nawożenia
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={ferts} />        
                        </AccordionBody>
                    </Accordion>


                    <Accordion open={open === 4} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(4)} className={`border-b-0 transition-colors text-md p-3  ${open === 4 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Dokumenty firmowe 
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={companys} />        
                        </AccordionBody>
                    </Accordion>



                    <Accordion open={open === 5} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(5)} className={`border-b-0 transition-colors text-md p-3 ${open === 5 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Dokumenty wewnętrzne 
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={internals} />        
                        </AccordionBody>
                    </Accordion>
        
                    <Accordion open={open === 6} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(6)} className={`border-b-0 transition-colors text-md p-3 ${open === 6 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Dokumenty pracowników 
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={personals} />        
                        </AccordionBody>
                    </Accordion>

                    <Accordion open={open === 7} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
                        <AccordionHeader onClick={() => handleOpen(7)} className={`border-b-0 transition-colors text-md p-3 ${open === 7 ? "text-blue-500 hover:!text-blue-700" : ""}`}>
                            Dokumenty urlopowe 
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal">
                        <FileTable documents={holidays} />        
                        </AccordionBody>
                    </Accordion>


                </CardBody>
            </Card>

            ${showDocumentCard === true ? <FileUploadCard close={handleHideCard} id={fileid} /> : ''}
        </>
    );
}

