import React, { useState } from 'react';


import {
    Alert,
    Card,
    Input,
    Button,
    Typography,
    IconButton,
    Textarea,

} from "@material-tailwind/react";



import { } from "@heroicons/react/24/outline";

import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { showAlert } from './Alert.jsx';
import { sendEmail } from './SendEmail';




export default function EmailCard(props) {


    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);

    const [name, setName] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [message, setMessage] = useState(props.message);
    const [subject, setSubject] = useState(props.subject);

    const [action, setAction] = useState("sensemail");
    const [encoding, setEncoding] = useState('');
    
    const [sendDisabled, setSendDisabled] = useState(false);



    function cancelClose() {
        props.close();
    }


    function messageUpdate(e) {
        setMessage(e);
    }



    function handleDataResponse(data) {

        console.log(data);
        setSendDisabled(false); //tymczasowo odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);

            if (closeOnSend) { props.close(refreshAfterExit); }

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }



    function handleSubmit(e) {
        e.preventDefault();
        setSendDisabled(true); //tymczasowo zablokuj przycisk wyślij
        let html="<html>" + message + "</html>"
        sendEmail(session, email, subject, message, html, handleDataResponse); //wyślij email
    }



    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto z-[30] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-full max-w-[20rem] mx-auto rounded-lg max-h-[94vh] overflow-y-auto" shadow={false}>

                <div className="flex items-center justify-between gap-4 w-auto bg-gradient-to-br from-teal-500/75 to-cyan-500/75 pt-2 pb-2 rounded-t-lg">
                    <Typography color="white" variant="h6" className="mt-2 pl-12 font-normal mx-auto">
                        Wyślij Email
                    </Typography>
                    <IconButton variant='text' className='right-2' color="white" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>
                <form className="mt-6 mb-2 mx-auto w-full max-w-[20rem]  items-center justify-center px-6" onSubmit={handleSubmit}>
                    <div className="mb-6 flex flex-col gap-4 w-auto items-center justify-center ">


                        <Input size="md" label="Nazwa odbiorcy" className="shadow-md w-auto" value={name} readOnly={true} />

                        <Input size="md" label="Adres email" className="shadow-md" value={email} onChange={e=> setEmail(e.target.value)} />

                        <Input size="md" label="Temat wiadomości" className="shadow-md" value={subject} onChange={e=> setSubject(e.target.value)} />

                        <Textarea label="Wiadomość" value={message} onChange={e => messageUpdate(e.target.value)} />

                        <Button className="items-center max-w-[10rem] my-0 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 mx-32" disabled={sendDisabled} data-refresh={true} fullWidth type="submit">
                            Wyślij
                        </Button>
                    </div>

                </form>
            </Card>

        </div>
    );
}

