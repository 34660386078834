import React, { useState } from 'react';


import {
    Alert,
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
  } from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { showAlert } from './Alert.jsx';

export default function CrewCard(props) {

    const [crew, setCrew] = useState(JSON.parse(localStorage.getItem("crew")) || []);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [action, setAction] = useState('create');
    const [id, setId] = useState(0);
    const [rank, setRank] = useState();

    
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);
    
    

    const [refreshAfterExit, setRefreshAfterExit] = useState(false);

    React.useEffect(() => {
        if (Array.isArray(crew)) {
            var member = crew.filter(m => m.id === Number(props.id))[0];
            if (member) {
                console.log(member);
                setId(Number(props.id));
                if (props.id>0) setAction('update');
                
                setFirstName(member.firstname);
                setLastName(member.lastname);
                setEmail(member.email);
                setPhone(member.phone);
                setLogin(member.login);
                setRank(member.rank);
            }
        }
    }, []);


    function cancelClose() {
        props.close(refreshAfterExit);
    }


    function handleDataResponse(data) {

        console.log(data)
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);
            //props.close(true);
        }

        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }

    function changeRank(e) {
        const rank = e.currentTarget.value;
        console.log(rank);
        setRank(rank);
    }


    

    function handleSubmitMember(e) {
        e.preventDefault();
        var bodydata = JSON.stringify({ action: action, session: session, login: login, password: password, name: '', firstname: firstname, lastname: lastname, email: email, phone: phone, newrank: rank, id: id });
        console.log(bodydata);
        fetch('https://agro-online.pl/cgi-bin/user.py', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodydata,
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => handleDataResponse(data))
    }

    const data = [
        {
            label: "Admin",
            value: '10',
            icon: UserCircleIcon,
            desc: '',
        },
        {
            label: "Technik",
            value: '5',
            icon: Cog6ToothIcon,
        },
    ];



    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto flex place-content-center'>

            <Card color="white" className="w-full max-w-[32rem] mx-auto max-h-[94vh] overflow-y-auto z-[20]" shadow={true}>

                <div className="flex items-center justify-between gap-4 w-auto bg-gray-100 pt-6 pb-2">
                    <Typography color="gray" className="mt-2 pl-12 font-normal mx-auto">
                        Karta danych pracownika.
                    </Typography>
                    <IconButton variant='text' className='top-[-1rem] right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>
                <form className="mt-4 mb-2 mx-auto w-full max-w-[calc(100vw/4)] sm:w-80 items-center justify-center p-2" onSubmit={handleSubmitMember}>
                    <div className="mb-6 flex flex-col lg:gap-4 gap-2 w-auto items-center justify-center ">

                    <div className="flex gap-2">
                            <Radio name="type" size="small" value={10} onClick={changeRank} label={
                                <div>
                                    <Typography color="blue-gray" className="font-normal text-sm">
                                        Admin
                                    </Typography>
                                </div>} containerProps={{
                                    className: "mt-0",
                                }} checked={rank == 10} />
                            <Radio name="type" size="small" value={5} onClick={changeRank} label={
                                <div>
                                    <Typography color="blue-gray" size="small" className="font-normal text-sm">
                                        Technik
                                    </Typography>
                                    <Typography variant="small" size="small" color="gray" className="font-normal text-xs">
                                        Próbobiorca
                                    </Typography>
                                </div>} checked={rank == 5} containerProps={{
                                    className: "mt-0",
                                }} />
                        </div>

                        <div className="flex items-center gap-4 w-auto my-2">
                            <Input size="md" label="Imię" className="shadow-md w-auto" value={firstname} onChange={e => setFirstName(e.target.value)} />
                            <Input size="md" label="Nazwisko" className="shadow-md w-auto" value={lastname} onChange={e => setLastName(e.target.value)} />
                        </div>
                        <Input size="md" label="Email" className="shadow-md" value={email} onChange={e => setEmail(e.target.value)} />

                        <div className="flex items-center gap-4 w-auto mx-8 my-2">
                            <Input size="md" label="Telefon kontaktowy" className="shadow-md" value={phone} onChange={e => setPhone(e.target.value)} />
                        </div>



                        <div className="flex items-center gap-4 mt-4">
                            <Input size="md" label="Login (może być email)" className="shadow-md" value={login} onChange={e => setLogin(e.target.value)} />
                            <Input size="md" label="Hasło" className="shadow-md" onChange={e => setPassword(e.target.value)} />

                        </div>
                        <span color="gray" variant="small" className="w-40 relative text-xs font-normal ml-56 top-[-16px] my-0">Pozostaw to pole puste, jesli nie chcesz zmienić hasła.</span>

                        <Button className="items-center my-0 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 mx-32" data-refresh={true} fullWidth type="submit">
                            Zapisz
                        </Button>
                    </div>

                </form>
            </Card>

        </div>
    );
}

