import React, { useRef, useState } from "react";




export function sendEmail(session, address, subject, text, html , callback) {
    var bodydata = JSON.stringify({ action: "sendemail", session: session, address: address, subject: subject, text: text, html: html });

    console.log(bodydata);
    fetch('https://agro-online.pl/cgi-bin/sms.py', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: bodydata,
      mode: 'cors'
    })
      .then(res => res.json())
      .then(data => callback(data))
      .catch(error => {
        console.log({ errorMessage: error.toString() });
        console.error('There was an error!', error);
    });

  }

  


////Twilio pass: 2EgnA49o%4[(.u{!R@+!OL-/}OT8tsuN
////smsapi pass:  2EgnA49o%4[(.u{!R@+!OL-/}OT8tsuN
///smsapi token : SxpBX2HpeqdtPnVd2egpeUFKlo6impbANwNjjA8P	
